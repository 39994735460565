import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSeatPrice'
})
export class GetSeatPricePipe implements PipeTransform {

  transform(seatList: any[], segment: string, seat: string, passengerRef: string): string {
    if (!seatList || !segment || !seat || !passengerRef) { return ''; }

    const column = seat.slice(-1);
    const row = parseInt(seat.slice(0, -1), 10);

    // Find the seat matching segment, location, and passengerRef
    const matchingSeat = seatList.find(s =>
      s.segment === segment &&
      s.passengerRefs === passengerRef &&
      s.location.row === row &&
      s.location.column === column
    );

    if (!matchingSeat) { return ''; }

    const price = matchingSeat.price?.consumer?.total || 0;
    const currency = matchingSeat.price?.consumer?.currency || '';

    return price > 0 ? `${price} ${currency}` : 'Free';
  }
}
