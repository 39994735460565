<div class="row flight-segment">
  <div class="col-md-7 offer-segment-det-wp">
    <div class="seg-flight-detail-wp">
      <div class="flight-route-detail">
        {{flightSegment.marketingCarrier ? ((flightSegment.marketingCarrier.airlineID + flightSegment.marketingCarrier.flightNumber) | formatFlightNumber) : ''}} -
        <span
          [appTooltip]="{airportCode: flightSegment.originDestination.departure?.airportCode, terminalName: flightSegment.originDestination.departure?.terminalName}">
          {{ flightSegment.originDestination.departure?.airportCode }}
        </span>
        <span>
          {{isPDFExtract ? '-' : '→'}}
        </span>
        <span
          [appTooltip]="{airportCode: flightSegment.originDestination.arrival?.airportCode, terminalName: flightSegment.originDestination.arrival?.terminalName}">
          {{ flightSegment.originDestination.arrival?.airportCode }}
        </span>
      </div>
      <div class="smaller-text">
        <div *ngIf="flightSegment.operatingCarrier.airlineID"
             class="mb-1 d-flex align-items-center gap-7 w-fit cursor-default"
             popoverClass="operated-by-popover"
             container="body"
             [ngbPopover]="operatedByRowPopover"
             [triggers]="'hover'"
             placement="top">
          <span>Operated by:</span>
          <app-airline-img
            [airlineID]="flightSegment.operatingCarrier.airlineID"
            [aircraftCode]="flightSegment.equipment.aircraftCode"
            [title]="(flightSegment.operatingCarrier.airlineID | getAirline) + ' - ' + flightSegment.operatingCarrier.name ">
          </app-airline-img>
          <span>{{flightSegment.operatingCarrier.airlineID}}</span>
          <ng-container *ngIf="!isPDFExtract; else pdfAircraftCodeTemplate">
            <app-aircraft-icon
              [aircraftCode]="flightSegment.equipment.aircraftCode">
            </app-aircraft-icon>
          </ng-container>

          <ng-template #operatedByRowPopover>
            <app-operated-by-popover
              [operatingFlightNumber]="(flightSegment.operatingCarrier?.airlineID | getAirline) + ' ' + flightSegment.operatingCarrier?.flightNumber"
              [aircraftCode]="flightSegment.equipment.aircraftCode">
            </app-operated-by-popover>
          </ng-template>

          <ng-template #pdfAircraftCodeTemplate>
            <ng-container *ngIf="flightSegment.equipment.aircraftCode as aircraftCode"
                          [ngSwitch]="aircraftCode">
              <span *ngSwitchCase="aircraftCodes.BUS">(Bus)</span>
              <span *ngSwitchCase="aircraftCodes.TRAIN.includes(aircraftCode) ? aircraftCode : ''">(Train)</span>
              <span *ngSwitchDefault>(Plane: {{aircraftCode}})</span>
            </ng-container>
          </ng-template>
        </div>

        <div>
          Class:
          <span *ngIf="flightSegment?.detail?.classOfService.code">
            <span *ngIf="oldFlightSegment && oldFlightSegment?.detail?.classOfService.code !== flightSegment?.detail?.classOfService.code"
                  class="old-info">
              <b>{{oldFlightSegment.detail.classOfService.code}}</b>
            </span>
            <b>{{flightSegment.detail.classOfService.code}}</b>,
          </span>
          <ng-container *ngIf="flightSegment.detail.classOfService.cabinDesignator">
            <span *ngIf="oldFlightSegment && oldFlightSegment?.detail?.classOfService.cabinDesignator !== flightSegment.detail.classOfService.cabinDesignator"
                  class="old-info">
              {{oldFlightSegment.detail.classOfService.cabinDesignator}}
            </span>
            {{flightSegment.detail.classOfService.cabinDesignator}}
          </ng-container>
        </div>

        <div *ngIf="fareNames[flightIdx][segIdx].fareStandard">
          Fare:
          <span *ngIf="oldFareNames && oldFareNames[flightIdx][segIdx].fareStandard !== fareNames[flightIdx][segIdx].fareStandard" class="old-info">
          {{ oldFareNames[flightIdx][segIdx].fareStandard }}
          </span>
          <span> {{ fareNames[flightIdx][segIdx].fareStandard }} </span>
        </div>
        <div *ngIf="flightSegment?.detail?.seatsLeft">
          Seats left: {{  flightSegment?.detail?.seatsLeft }}
        </div>
        <div *ngIf="fareNames[flightIdx][segIdx].fareBasisCode">
          FareBasisCode: {{ fareNames[flightIdx][segIdx].fareBasisCode }}
        </div>

        <div class="fare-item-wp" *ngIf="fareNames">
          <div class="fare-item"
               *ngIf="fareNames[flightIdx][segIdx] && fareNames[flightIdx][segIdx].fareStandard">
            <div class="fare-icons">
              <ng-container *ngIf="filteredFareNames?.length && !disclosures">
                <app-air-icons-info
                  *ngFor="let desc of filteredFareNames"
                  [icon]="desc">
                </app-air-icons-info>
                <app-air-icons-info
                  icon="contact_support"
                  [showPopup]="true"
                  [segment]="flightSegment"
                  [popupData]="filteredFareNames">
                </app-air-icons-info>
              </ng-container>
              <ng-container *ngIf="!isPDFExtract && disclosures && flightKey">
                <app-disclosures
                  [disclosures]="disclosures"
                  [flightKey]="flightKey"
                >
                </app-disclosures>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5" style="text-align: end">
    <div class="flight-time">{{flightSegment.originDestination.departure.time}} - {{flightSegment.originDestination.arrival.time}}
      <span class="flight-time-code" *ngIf="flightSegment.detail.duration">({{flightSegment.detail.duration | parseDuration}})</span>
    </div>
    <div
      class="flight-date">
      {{(flightSegment.originDestination.departure.date | dateFormat) | async}}
      <span *ngIf="flightSegment.originDestination.departure.date !== flightSegment.originDestination.arrival.date"
            style="color: #CD0005 !important">
        {{isPDFExtract ? '-' : '→'}} {{(flightSegment.originDestination.arrival.date | dateFormat) | async}}
      </span>
    </div>
    <div *ngIf='flightSegment?.detail?.segmentType && isPDFExtract'>
      <ng-container *ngIf="flightSegment?.detail?.segmentTypeDefinition; else defaultSegmentTypeDefinition">
        <div> {{flightSegment?.detail?.segmentType}} ({{flightSegment?.detail?.segmentTypeDefinition}})</div>
      </ng-container>
    </div>
    <ng-container *ngIf='flightSegment?.detail?.segmentType && !isPDFExtract'>
      <i class="material-icons {{flightSegment?.detail?.segmentType | getColor}}" [ngbPopover]="segmentType"
         popoverClass="segment-type-popover-wp"
         [triggers]="'hover'"
         placement="top"
         container="body"
      >
        circle
      </i>
      <ng-template #segmentType>
        <ng-container *ngIf="flightSegment?.detail?.segmentTypeDefinition; else defaultSegmentTypeDefinition">
          <div class="segment-type-tooltip-wp">
            <div> {{flightSegment?.detail?.segmentType}} ({{flightSegment?.detail?.segmentTypeDefinition}})</div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>

    <div *ngIf="flightSegment.detail?.stopLocations?.length > 0" class="pt-3">
      <div
        class="stops d-inline-block"
        [ngbPopover]="stopsInfo"
        placement="top"
        [triggers]="'hover'"
      >
        Stops({{flightSegment.detail?.stopLocations?.length}})
      </div>
      <ng-template #stopsInfo>
        <div *ngFor="let stop of flightSegment.detail?.stopLocations">
          <div class="d-flex flex-column align-items-center">
            <span class="font-weight-bold">{{stop.airportCode}}</span>
            <span *ngIf="stop.arrivalDate === stop.departureDate; else diffDays">{{(stop.arrivalDate | dateFormat) | async}}</span>
            <ng-template #diffDays>{{(stop.arrivalDate | dateFormat) | async}}-{{(stop.departureDate | dateFormat) | async}}</ng-template>
            <span>{{stop.arrivalTime}}-{{stop.departureTime}}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #defaultSegmentTypeDefinition>
  <div class="segment-type-tooltip-wp" [ngSwitch]="flightSegment?.detail?.segmentType">
    <div *ngSwitchCase="'HK'">{{flightSegment?.detail?.segmentType}} (confirmed)</div>
    <div *ngSwitchCase="'UN'">{{flightSegment?.detail?.segmentType}} (unconfirmed)</div>
    <div *ngSwitchDefault>{{flightSegment?.detail?.segmentType}} (other status)</div>
  </div>
</ng-template>
