import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NDCApiService} from '../../../shared/services/ndc-api.service';
import {HelpersService} from '../../../shared/services/helpers.service';
import {ORDER_STATUS, ROLES} from '../../../shared/constants';
import {GtmService} from '../../../shared/services/gtm.service';
import {LocalStorageService} from '../../../shared/services/local-storage.service';
import {convertPhoneIntoString} from '../../../shared/adapters/v1_2/adapters';
import {SentryService} from '../../../shared/services/sentry.service';
import {PaymentModuleService} from "../../../shared/services/payment-module.service";
import { ErrorAlert } from "../../../shared/models/error-alert";


@Component({
  selector: 'app-service-list-modal',
  templateUrl: './service-list-modal.component.html',
  styleUrls: ['./service-list-modal.component.scss']
})
export class ServiceListModalComponent implements OnInit {

  @Input() order;
  @Input() owner;
  @Input() id;
  @Input() selectedServicesMapCopy = {};
  @Input() selectedServicesCopy = [];
  @Output() emitDismiss = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitSendOrderChangeSuccess = new EventEmitter();
  @ViewChild('cardBodyContainer', {static: true}) cardBodyContainer: ElementRef;

  step = 1;
  paymentObject;
  validateTriggered = false;
  showServiceListLoader = false;
  paymentLoader = false;
  serviceListError: any = {};
  responseWarnings = [];
  orderChangeError: ErrorAlert = new ErrorAlert();
  orderChangeWarnings = [];
  servicesPerTraveler = {};
  selectedServices = [];
  selectedServicesMap = {};
  servicesTotalPrice = 0.0;
  validationErrors: any = {};
  ORDER_STATUS = ORDER_STATUS;
  ROLES = ROLES;
  segments = [];
  isPaymentInfoError = false;


  constructor(
    public service: NDCApiService,
    public ls: LocalStorageService,
    private helpers: HelpersService,
    private gtmService: GtmService,
    private sentryService: SentryService,
    private cdr: ChangeDetectorRef,
    protected paymentService: PaymentModuleService,
  ) {}

  ngOnInit() {
    this.service.owner = this.sentryService.owner = this.owner;
    setTimeout(() => this.actionDisplayServiceList(), 0);
    this.order.allowedPaymentMethods.none = !!this.order.allowedRequests.ticketed.AncillariesOnHold;
  }

  setStep(step: number) {
    this.orderChangeError = new ErrorAlert();
    this.step = step;
  }

  actionDisplayServiceList() {
    let body = {
      id: this.id,
      passengers: this.order.passengers
    };
    switch (this.ls.appVersion) {
      case 'v1.2':
        body.passengers = body.passengers.map((item) => {
          return {
            travelerReference: item.travelerReference
          };
        });
        break;
    }

    this.serviceListError = {};
    this.responseWarnings = [];

    if (Object.keys(this.servicesPerTraveler).length === 0) {
      this.servicesPerTraveler = {};
      this.showServiceListLoader = true;

      return this.service.sendServiceList(body)
        .then((res: any) => {
          this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
          this.responseWarnings = this.helpers.getWarningsFromResponse(res);
          if (res?.services && res?.segments) {
            ({
              servicesPerTraveler: this.servicesPerTraveler,
            } = this.helpers.groupServicesBySegment(res.services, res.segments));
            this.segments = res.segments;
          }
          this.showServiceListLoader = false;
        })
        .catch((res) => {
          this.showServiceListLoader = false;
          this.serviceListError = this.helpers.getError(res);
          this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
          if (this.helpers.isCriticalError(res)) {
            throw res;
          }
        });
    }
    return Promise.resolve();
  }

  onRemoveService(srv, travelerReferences) {
    this.removeSelectedService(srv, travelerReferences);
    this.applyNewServiceSelection();
    this.orderChangeError = new ErrorAlert();
    if (!this.selectedServicesCopy.length) {
      this.setStep(1);
    }
  }

  removeSelectedService(service, passengerRef) {
    let code = service.serviceID + passengerRef + service.segmentReferences;
    const copyService = JSON.parse(JSON.stringify(service));
    if (this.selectedServicesMapCopy[code]) {
      service.bookingInstructions?.instructions?.forEach((instruction, instructionIdx) => {
        delete this.validationErrors[code + instructionIdx];
      });
      delete this.selectedServicesMapCopy[code];
      this.selectedServicesCopy = this.selectedServicesCopy.filter((srv) => {
        return srv.serviceID + srv.segmentReferences + srv.travelerReferences !== copyService.serviceID + copyService.segmentReferences + passengerRef;
      });
    }
  }

  applyNewServiceSelection() {
    this.selectedServices = JSON.parse(JSON.stringify(this.selectedServicesCopy));
    this.selectedServicesMap = JSON.parse(JSON.stringify(this.selectedServicesMapCopy));
    this.servicesTotalPrice = this.getTotalPrice();

    this.setStep(2);
  }

  getTotalPrice() {
    let totalPrice = 0.0;
    this.selectedServices.map(srv => {
      totalPrice += srv.price.consumer.total * (srv.quantity || 1);
    });
    return +totalPrice.toFixed(2);
  }

  isActiveOrder() {
    return this.order.status === 'Pending' || this.order.status === 'Ticketed';
  }

  showSelectedServices() {
    if (Object.keys(this.validationErrors).length === 0) {
      this.applyNewServiceSelection();
      this.setStep(2);
    }
  }

  onStepBack() {
    this.setStep(3);
    this.orderChange();
    this.cdr.detectChanges();
  }

  proceedStep3() {
    if (!this.validate()) {
      return;
    }
    this.setStep(4);
  }

  validate() {
    this.validateTriggered = true;
    return this.paymentService.form.valid;
  }

  orderChange(resetPayment = false) {
    if (!this.selectedServices.length) {
      return;
    }

    this.paymentObject = this.paymentService.getPaymentData(resetPayment);

    const services = this.helpers.mapServices(this.selectedServices);

    let body = {
      id: this.id,
      payment: this.paymentObject,
      services: services,
      passengers: JSON.parse(JSON.stringify(this.order.passengers)),
    };
    switch (this.ls.appVersion) {
      case 'v1.2':
        if (body.payment && body.payment.phone) {
          body.payment.phone = convertPhoneIntoString(body.payment.phone);
        }
        break;
    }

    if (this.paymentObject?.method === 'agencyCard' || this.paymentObject?.method === 'agencyCash') {
      body.payment = {
        method: this.paymentObject.method,
      };
    }

    body.passengers = body.passengers.map((item) => {
      delete item.document;
      delete item.documents;
      return item;
    });

    if (this.order.status === ORDER_STATUS.PENDING) {
      delete body.payment;
    }

    this.paymentLoader = true;
    this.orderChangeError = new ErrorAlert();
    this.orderChangeWarnings = [];

    this.service.sendOrderChange(body, false)
      .then((res: any) => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
        this.gtmService.addEvent('OrderChange.AddServices');
        this.showServiceListLoader = false;
        this.paymentLoader = false;

        this.orderChangeWarnings = this.helpers.getWarningsFromResponse(res);

        this.setStep(5);
        this.emitSendOrderChangeSuccess.emit(res);
      })
      .catch((res) => {
        this.paymentLoader = false;
        this.cardBodyContainer.nativeElement.scrollTop = 0;
        this.orderChangeError = this.helpers.getError(res);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }

  onEmitUpdatedValues({selectedServicesCopy, selectedServicesMapCopy, validationErrors}) {
    this.selectedServicesCopy = selectedServicesCopy;
    this.selectedServicesMapCopy = selectedServicesMapCopy;
    this.validationErrors = validationErrors;
  }
}
