<div class="mt-3">
  <div class="row">
    <div class="col-12">
      <h1 class="mb-0">Manage Agents for {{agency ? agency.name : ls.agency}}</h1>
    </div>
    <div class="col-12" *ngIf="isAddNewAgentAvailable">
      <button class="btn btn-primary rounded float-right"
              title="Add agents"
              (click)="openModal(addNewAgentModal)">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>
  <div class="mt-4" *ngIf="agents?.length">
    <div class="table-responsive">
      <table class="table table-default table-hover mb-0">
        <thead>
        <tr>
          <th scope="col">Email</th>
          <th scope="col">Agent ID</th>
          <th scope="col">Role</th>
          <th scope="col">Invitation status</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let agent of agents | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let idx = index"
            [class.inactive]="agent.status === 'inactive'">
          <td>{{agent.email}}</td>
          <td>
            <input [(ngModel)]="agent.agent_id" (ngModelChange)="onChange(idx)"
                   type="text"
                   placeholder="123456"
                   class="form-control w-50 m-auto">
          </td>
          <td>
            <select class="form-control" [(ngModel)]="agent.role" (ngModelChange)="onChange(idx)">
              <option value="{{agent.role}}" hidden>{{agent.role | titlecase}}</option>
              <option value='{{role.toLowerCase()}}' *ngFor='let role of agentsRoles'>{{role}}</option>
            </select>
          </td>
          <td>
            {{agent.is_verified ? 'Accepted' : 'Invited'}}
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end gap-12">
              <button *ngIf="!agent.is_verified"
                      type="button"
                      class="btn btn-sm btn-tonal-primary"
                      (click)="handleInvitation('revoke', agent)">
                Revoke
              </button>

              <button *ngIf="!agent.is_verified"
                      type="button"
                      class="btn btn-sm btn-tonal-primary"
                      (click)="handleInvitation('resend', agent)">
                Resend
              </button>

              <div class="custom-control custom-switch cursor-pointer" *ngIf="agent.email !== ls.email">
                <input type="checkbox"
                       class="custom-control-input"
                       [id]="'agent_status_' + agent.id"
                       [ngModel]="agent.status === 'active'"
                       (ngModelChange)="onChange(idx); onStatusChange($event, agent)"
                       [disabled]="!agent.is_verified">
                <label class="custom-control-label" [for]="'agent_status_' + agent.id"></label>
              </div>

              <i *ngIf="agent.email !== ls.email"
                 class="material-symbols-outlined remove-icon"
                 title="Remove"
                 (click)="onRemoveAgentClick(agent)">
                delete
              </i>

              <ng-container *ngIf="agentHasChanges[idx]">
                <span class="btn btn-tonal-primary" (click)="saveChanges(agent, idx)">Save</span>
                <span class="btn btn-secondary" (click)="cancelChanges(agent, idx)">Cancel</span>
              </ng-container>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row mt-5" *ngIf="!agents?.length">
    <div class="col">
      No agents found
    </div>
  </div>
</div>

<ngb-pagination *ngIf="agents?.length > pageSize"
                class="d-flex justify-content-end mt-4"
                [collectionSize]="agents.length"
                [(page)]="page"
                [pageSize]="pageSize"
                [maxSize]="7"
                [boundaryLinks]="true"
                (pageChange)="onPageChange()"
></ngb-pagination>

<ng-template #addNewAgentModal let-c="close" let-d="dismiss">
  <div class="modal-card card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">New Agents</h3>
        </div>
        <div class="col-auto">
          <i
            data-dismiss="modal"
            aria-hidden="true"
            (click)="d('closed')"
            class="material-icons float-right close-icon">
            close
          </i>
        </div>
      </div>
    </div>
    <div class="card-body row">
      <div class="form-group-sm col-md-4">
        <label class="font-weight-bold">Role</label>
        <select class="form-control" [(ngModel)]="model.role">
          <option value="" hidden selected>Select role</option>
          <option value='{{role.toLowerCase()}}' *ngFor='let role of agentsRoles'>{{role}}</option>
        </select>
        <div class="errors" [hidden]="!errors.role">{{errors.role | capitalizeFirstLetter}}</div>
      </div>
      <div class="form-group-sm col-lg-12">
        <label class="font-weight-bold">Email</label>
        <input
          [(ngModel)]="model.email"
          class="form-control"
          type="text"
          placeholder="e.g. agent1@email.com, agent2@email.com">
        <div class="errors" [hidden]="!errors.email">{{errors.email | capitalizeFirstLetter}}</div>
      </div>
      <div class="col-lg-12">
        <div *ngIf="errors.error?.summary" class="alert-danger p-3 mb-3 rounded">{{errors.error.summary}}</div>
        <error-alert-block
          [error]="agentsError">
        </error-alert-block>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="button"
        class="btn btn-secondary mr-2"
        (click)="d('closed')">Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]='!(model.email && model.role)'
        (click)="addAgent(c);">Add
      </button>
    </div>
  </div>
</ng-template>

<ng-template #removeAgentInfoModal let-c="close" let-d="dismiss">
  <div class="modal-header" ngbAutoFocus>
    <h3 class="card-title">Remove agent</h3>
  </div>
  <div class="modal-body">
    <h3 class="font-weight-normal lh-24">Are you sure you want to remove the agent <b>{{selectedAgent.email}}</b> from the agency?</h3>
    <div class="custom-control custom-checkbox mt-5 mr-sm-2">
      <input type="checkbox" class="custom-control-input" id="customControlInline" (change)="ls.isRemoveAgentInfoModalSkipped = $event.target.checked">
      <label class="custom-control-label fs-16" for="customControlInline">Do not show this message again</label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="d('closed')">Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="removeAgent(selectedAgent); c('ok')">Remove
    </button>
  </div>
</ng-template>


