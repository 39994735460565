import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef} from '@angular/core';
import {SeatMapService} from '../../shared/services/seat-map.service';
import {
  fadeInAnimation,
  fadeInUpAnimation,
  rotateAnimation
} from "angular-animations";

@Component({
  selector: 'app-seat-map',
  templateUrl: './seat-map.component.html',
  styleUrls: ['./seat-map.component.scss'],
  animations: [
    fadeInUpAnimation(),
    fadeInAnimation(),
    rotateAnimation({duration: 500 })
  ]
})
export class SeatMapComponent implements OnInit, OnChanges {

  @Input() seats = [];
  @Input() segments;
  @Input() passengers;
  @Input() seatAvailability;

  @Output() seatSelect = new EventEmitter();

  @ViewChild('disclosures') disclosures: ElementRef;

  segmentMatrix;
  selectedPassenger;
  seatList;
  exitsInfo = {};
  segmentDisclosures = {};
  generalDisclosures = [];
  allDisclosuresAreShown: boolean;
  showExpandIcon: boolean;
  allUniquePrices = [];
  priceRanges: { min: number; max: number }[];
  currency: string;

  constructor(public s: SeatMapService) {
  }

  ngOnInit() {
    this.segmentMatrix = this.s.segmentMatrix;
    this.createSeatList();
    this.selectEmergencyRows(this.seatAvailability);
    this.getDisclosuresHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.passengers) {
      this.changeSelectedPassenger(this.passengers[0]);
    }
  }

  selectSeat(segment, rowIdx, char) {
    this.s.selectSeat(segment, rowIdx, char, this.selectedPassenger);
    this.seatSelect.emit(true);
    this.s.selectedSeats[segment] = {...this.s.selectedSeats[segment]};

    for (let i = 0; i < this.passengers.length; i++) {
      if (!Object.values(this.s.selectedSeats[segment]).includes(this.passengers[i].travelerReference)) {
        this.changeSelectedPassenger(this.passengers[i]);
        break;
      }
    }
  }

  changeSelectedPassenger(p) {
    if (p?.travelerReference) {
      this.selectedPassenger = p.travelerReference;
      this.s.prepareSeatsPerSegment(this.seatAvailability, p.travelerReference);
    }
  }

  selectEmergencyRows(seatAvailability) {
    if (seatAvailability && seatAvailability.exits) {
      let segmentsTemp = [];
      seatAvailability.exits.map((exit) => {
        if (!segmentsTemp.includes(exit.segmentID)) {
          segmentsTemp.push(exit.segmentID);
        }
        segmentsTemp.forEach((segment) => {
          this.exitsInfo[segment] = [];
          seatAvailability.exits.forEach(ex => {
            if (ex.segmentID === segment) {
              this.exitsInfo[segment].push(ex.row);
            }
          });
        });
      });
    }
  }

  createSeatList() {
    let seats = {};
    let seatList = [];
    let disclosures = [];
    if (this.seatAvailability) {
      seatList = this.seatAvailability.seatList;
      disclosures = this.seatAvailability.disclosures;
      this.seatAvailability.seatDisplay.map(leg => {
        this.segmentDisclosures[leg.segment] = {};
        seats[leg.segment] = {};
      });
      seatList.map(seat => {
        if (!this.currency) {
          this.currency = seat.price.consumer.currency;
        }
        seat['disclosures'] = [];
      });
    }

    if (disclosures && disclosures.length) {
      Object.keys(seats).map(item => {
        this.segmentDisclosures[item] = [];
        disclosures?.map(disclosure => {
          disclosure.descriptions.map(disc => {
            let obj = {
              [disc.text]: false
            };
            seatList.map(seat => {
              if (seat.segment === item) {
                seats[item][seat.location.row + seat.location.column] = seat;
              }
              if (disclosure.listKey === seat.listKey) {
                seat['disclosures'].push(disc);
                obj[disc.text] = true;
              } else if (disclosure.listKey === item) {
                this.segmentDisclosures[item].push(disc);
                obj[disc.text] = true;
              }
              seat['disclosures'] = [...new Map(seat['disclosures'].map(item =>
                [item['text'], item])).values()];
            });

            if (!obj[disc.text]) {
              this.generalDisclosures.push(disc);
            }
          });
        });
        this.segmentDisclosures[item] = [...new Map(this.segmentDisclosures[item].map(item =>
          [item['text'], item])).values()];

        this.generalDisclosures = this.segmentDisclosures[item].length ? this.generalDisclosures.filter(it => {
          return this.segmentDisclosures[item].filter(it2 => {
            return it.text === it2.text;
          }).length === 0;
        }) : [...new Map(this.generalDisclosures.map(item =>
          [item['text'], item])).values()];
      });
    }

    this.allUniquePrices = this.getAllUniquePrices(seatList);
    this.priceRanges = this.getPriceRanges(this.allUniquePrices);
  }

  getAllUniquePrices(seatList: any[]) {
    return Array.from(
      new Set(
        seatList.map(item => item.price.consumer.total)
      )
    ).sort((a, b) => a - b);
  }

  getPriceRanges(prices: number[]): { min: number; max: number }[] {
    const maxColors = 10; // Maximum number of colors
    if (prices.length <= maxColors) {
      // Return individual prices if the count is less than or equal to maxColors
      return prices.map(price => ({ min: price, max: price }));
    }

    const groupSize = Math.ceil(prices.length / maxColors);
    const priceRanges: { min: number; max: number }[] = [];

    for (let i = 0; i < prices.length; i += groupSize) {
      const groupPrices = prices.slice(i, i + groupSize);
      priceRanges.push({
        min: groupPrices[0],
        max: groupPrices[groupPrices.length - 1]
      });
    }

    return priceRanges;
  }

  getDisclosuresHeight() {
    setTimeout(() => {
      if (this.disclosures) {
        this.allDisclosuresAreShown = this.disclosures.nativeElement.offsetHeight < 120;
        this.showExpandIcon = !this.allDisclosuresAreShown;
      }
    }, 100);
  }

  toggleReadDisclosures() {
    this.allDisclosuresAreShown = !this.allDisclosuresAreShown;
  }
}
