import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Dictionary } from '../../shared/types/dictionary';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search-settings-modal',
  templateUrl: './search-settings-modal.component.html',
  styleUrls: ['./search-settings-modal.component.scss']
})
export class SearchSettingsModalComponent implements OnInit {
  readonly Number = Number;

  @Output() emitDismiss = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @ViewChild('popover') popover: NgbPopover;

  enableLimitOfOffers: boolean;
  maxOffersPerCabin: number;
  stopsAmount: number;
  searchModes = Dictionary.SearchModes;
  searchMode: string;
  requestTimeout: number;
  avoidDisclosures: boolean;

  buttonName = {
    enableLimitOfOffers: 'help_outline',
    searchMode: 'help_outline',
    requestTimeout: 'help_outline',
    avoidDisclosures: 'help_outline'
  };
  constructor(public ls: LocalStorageService,
              private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target) || !event.target.className.includes('help-icon')) {
      this.buttonName = {
        enableLimitOfOffers: 'help_outline',
        searchMode: 'help_outline',
        requestTimeout: 'help_outline',
        avoidDisclosures: 'help_outline'
      };
    }
  }

  ngOnInit(): void {
    this.initializeSettings();
  }

  initializeSettings() {
    const settings = this.ls.settings || {};
    this.enableLimitOfOffers = settings.enableLimitOfOffers || false;
    this.maxOffersPerCabin = settings.maxOffersPerCabin || 1;
    this.stopsAmount = settings.stops || 10;
    this.searchMode = settings.searchMode || '';
    this.requestTimeout = settings.requestTimeout || 60;
    this.avoidDisclosures = settings.avoidDisclosures || false;
  }

  saveSearchSettings() {
    let settings = this.ls.settings;

    const updatedSettings = {
      ...settings,
      enableLimitOfOffers: this.enableLimitOfOffers,
      maxOffersPerCabin: this.maxOffersPerCabin,
      stops: this.stopsAmount,
      searchMode: this.searchMode,
      requestTimeout: this.requestTimeout,
      avoidDisclosures: this.avoidDisclosures,
    };

    this.ls.settings = updatedSettings;
  }

  updateButtonName(key: string, icon: string) {
    this.buttonName[key] = icon;
  }

}
