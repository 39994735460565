<i
  [ngbPopover]="popoverContent"
  popoverClass="popover-button"
  [autoClose]="'outside'"
  placement="bottom"
  class="material-icons fs-20"
  container="body"
  #popover="ngbPopover"
  (mouseover)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  {{ buttonName }}
</i>
<ng-content></ng-content>
