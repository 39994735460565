import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-operated-by-popover',
  templateUrl: './operated-by-popover.component.html',
  styleUrls: ['./operated-by-popover.component.scss']
})
export class OperatedByPopoverComponent implements OnInit {
  @Input() operatingFlightNumber: string | null = null;
  @Input() aircraftCode: string | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
