<div class="row">
  <div class="col mb-4">
    <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
  </div>

  <div class="col-12">
    <h1 class="mb-0">{{isNewRecord ? 'New Preset' : 'Edit Preset'}}</h1>
  </div>
  <div class="col mt-4">
    <div class="card">

      <div class="loader-wrapper" *ngIf="!(hubAgenciesService.isPresetsLoaded$ | async) || !(helpers.isProvidersLoaded$ | async)">
        <app-loader></app-loader>
      </div>

      <div class="card-body" [formGroup]="form" *ngIf="(hubAgenciesService.isPresetsLoaded$ | async) && (helpers.isProvidersLoaded$ | async)">

        <h4><span class="text-primary" *ngIf="isEdit">*</span> Name</h4>
        <div class="form-row">
          <div class="form-group-sm col-md-3" (click)="editPreset()">
            <input
              [validate]="form.get('title').dirty || validateTriggered"
              type="text"
              class="form-control"
              formControlName="title"
              placeholder="Trade Fares"
            />
          </div>
        </div>

        <h4><span class="text-primary" *ngIf="isEdit">*</span> Airline</h4>
        <div class="form-row">
          <div class="form-group-sm col-lg-2 col-md-3" (click)="editPreset()">
            <ng-select2
              [validate]="form.get('airline').dirty || validateTriggered"
              class="ng-select2-form-control"
              formControlName="airline"
              [placeholder]="'Select Airline ...'"
              (click)="helpers.setFocusToSelect2SearchField();"
              (valueChanged)="onSelectAirline($event)"
            >
              <option value=""></option>
              <option *ngFor="let provider of (helpers.allProvidersObj | pairs)" [value]="provider[0]">({{provider[0] | getProviderName}}) {{ provider[1] }}</option>
            </ng-select2>
          </div>
        </div>

        <h4 class="d-flex align-items-center gap-10">
          PTCs
          <ng-container [ngTemplateOutlet]="helpIcon"
                        [ngTemplateOutletContext]="{popoverText: 'Change/remove PTCs to the ones you need', btnKey: 'ptcs'}">
          </ng-container>
        </h4>
        <div class="form-row">
          <ng-container formGroupName='travelers'>
            <ng-container *ngFor="let defaultTravelerType of defaultTravelerTypes; let index = index;">
              <div class="form-group-sm col-lg-1 col-md-2">
                <label>{{defaultTravelerType}}</label>
                <ng-container *ngIf="form.get('travelers')['controls'].length; else addTravelerWrapper">
                  <ng-container *ngFor="let control of form.get('travelers')['controls']; let idx = index; first as first; last as last;" [formGroupName]="idx">
                    <ng-container *ngIf="defaultTravelerType === control.get('defaultType').value;">
                      <input
                        [validate]="control.get('travelerType').dirty || validateTriggered"
                        type="text"
                        class="form-control traveler-control"
                        formControlName="travelerType"
                        [placeholder]="defaultTravelerType"
                        [minLength]="travelerTypeMinLength"
                        [maxLength]="travelerTypeMaxLength"
                        autofocus
                        (input)="onTravelerTypeInputChange($event.target.value, control)"/>
                      <span class="reset-btn">
                        <span class="material-symbols-outlined" (click)="removeTraveler(idx)">
                          close
                        </span>
                      </span>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <button class="btn btn-tonal-primary add-traveler-btn rounded-sm py-2"
                        title="Add {{defaultTravelerType}}"
                        (click)="addNewTraveler(defaultTravelerType);">
                  <i class='fa fa-plus fs-10'></i>
                </button>

                <ng-template #addTravelerWrapper>
                  <div style="height: 40.5px">
                  </div>
                </ng-template>

              </div>
            </ng-container>
          </ng-container>
        </div>

        <h4 class="d-flex align-items-center gap-10">
          Preferences
          <ng-container [ngTemplateOutlet]="helpIcon"
                        [ngTemplateOutletContext]="{popoverText: 'Choose from the dropdown and/or create your own', btnKey: 'preferences'}">
          </ng-container>
        </h4>
        <div class="form-row">
          <div class="form-group-sm col-4" (click)="editPreset()">
            <div class="w-100 dropdown text-center" ngbDropdown #fareTypeDropdown="ngbDropdown" role="button" autoClose="outside">
              <div class="form-control"
                   [class.is-valid]="(form.get('preferences').dirty || validateTriggered) && form.get('preferences').valid"
                   [class.is-invalid]="form.get('preferences').invalid"
                   [class.disabled]="!isEdit"
                   aria-haspopup="true" aria-expanded="false"
                   ngbDropdownToggle>
                {{ ('No Preference' | isExistItemInArray:preferenceList:'title')
                ? 'No Preference'
                : (preferenceList.length +' Selected Preference')}}{{preferenceList.length > 1 ? 's' : ''}}
              </div>
              <div ngbDropdownMenu *ngIf="!isAddNewPreference">
                <a *ngFor="let control of form.get('preferences')['controls']; let idx = index; let first = first;"
                  class="dropdown-item inversed fare-dwn-item"
                  (click)="onSelectedPreference(control.value)"
                  [ngClass]="{'selected': control.value.title | isExistItemInArray:preferenceList:'title'}"
                  [class.disabled-preference]="preferenceList | getDisabledPreference : control.value"
                >
                  <span class="icon-container">
                    <i class="material-icons fare-icon"
                       *ngIf="control.value.title | isExistItemInArray:preferenceList:'title'">done</i>
                  </span>
                  <span class="fare-title">
                    {{control.value.code ? '(' + control.value.code + ')' : ''}} {{ control.value.title }}
                  </span>
                  <span class="remove-icon" *ngIf="!first">
                    <i class="material-icons" (click)="removePreference(idx, control.value); $event.stopPropagation();">
                      remove
                    </i>
                  </span>
                </a>
              </div>
            </div>
            <button *ngIf="!isAddNewPreference"
                    class="btn btn-tonal-primary rounded-sm mt-3 py-2"
                    title="Add preference"
                    (click)="addNewPreference();">
              <i class='fa fa-plus fs-10'></i>
            </button>
          </div>
        </div>

        <div class="form-row">
          <ng-container formGroupName='preferences' *ngIf="isAddNewPreference">
            <ng-container *ngFor="let control of form.get('preferences')['controls']; let idx = index; last as last;" [formGroupName]="idx">
              <ng-container class="form-row" *ngIf="last">
                <div class="form-group-sm col-md-1">
                  <label>Code</label>
                  <input
                    [validate]="control.get('code').dirty || validateTriggered"
                    type="text"
                    class="form-control mr-3"
                    formControlName="code"
                    placeholder="758"
                    maxlength="4"
                    autofocus/>
                </div>
                <div class="form-group-sm col-md-1">
                  <label><span class="text-primary" *ngIf="isEdit">*</span> Definition</label>
                  <input
                    [validate]="control.get('definition').dirty || validateTriggered"
                    type="text"
                    class="form-control mr-3"
                    formControlName="definition"
                    placeholder="PF"
                    autofocus/>
                </div>
                <div class="form-group-sm col-md-2">
                  <label><span class="text-primary" *ngIf="isEdit">*</span> Title</label>
                  <input
                    [validate]="control.get('title').dirty || validateTriggered"
                    type="text"
                    class="form-control mr-3"
                    formControlName="title"
                    placeholder="Public Fares"
                    autofocus/>
                </div>

                <div class="form-group-sm col-md-2">
                  <label>&nbsp;</label>
                  <div>
                    <button class="btn btn-primary mr-3" (click)="savePreference(control, idx);" [disabled]="control.invalid">Save</button>
                    <button class="btn btn-secondary" (click)="removePreference(idx);">Cancel</button>
                  </div>
                </div>

              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <h4 class="d-flex align-items-center gap-10">
          Qualifier
          <ng-container [ngTemplateOutlet]="helpIcon"
                        [ngTemplateOutletContext]="{popoverText: 'Ask airline for this information', btnKey: 'qualifier'}">
          </ng-container>
        </h4>
        <div class="form-row">
          <ng-container formGroupName="qualifier">
            <div class="form-group col-md-2" (click)="editPreset()">
              <label>Type</label>
              <div class="w-100 dropdown text-center" ngbDropdown #qualifierDropdown="ngbDropdown" role="button">
                <div class="form-control"
                     [class.is-valid]="form.enabled && (form.get('qualifier').get('type').dirty || validateTriggered)"
                     [class.disabled]="!isEdit"
                     aria-haspopup="true" aria-expanded="false"
                     ngbDropdownToggle>
                  {{buildQualifierName(form.get('qualifier').get('type').value) || 'Type'}}
                </div>
                <div ngbDropdownMenu>
                  <a *ngFor="let control of form.get('qualifiers')['controls']; let idx = index;"
                     class="dropdown-item inversed qualifiers-dwn-item"
                     (click)="selectQualifierType(control.value.qualifierType)"
                  >
                    <span>{{buildQualifierName(control.value.qualifierType)}}</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="form-group col-md-2" (click)="editPreset()">
              <label>Code</label>
              <input
                [validate]="form.get('qualifier').get('code').dirty || validateTriggered"
                type="text"
                class="form-control"
                formControlName="code"
                placeholder="PF"
              />
            </div>

            <div class="form-group col-lg-1 col-md-2">
              <label>Airline</label>
              <input
                [validate]="form.get('qualifier').get('airline').dirty || validateTriggered"
                type="text"
                class="form-control"
                formControlName="airline"
                placeholder="00"
                readonly
              />
            </div>
          </ng-container>
        </div>

        <h4>
          Tax Exemption
        </h4>

        <div class="form-row">
          <div class="col-md-6">
            <div class="form-row">
              <ng-container *ngIf="form.get('taxExemptions')['controls']?.length">
                <ng-container formGroupName='taxExemptions'>
                  <ng-container *ngFor="let control of form.get('taxExemptions')['controls']; let idx = index; last as last;" [formGroupName]="idx">
                    <div class="form-group-sm col-sm-2">
                      <label>Tax Code</label>
                      <input
                        [validate]="control.get('code').dirty || validateTriggered"
                        type="text"
                        class="form-control mr-3"
                        formControlName="code"
                        placeholder="CO"
                        autofocus/>
                    </div>
                    <div class="col-sm-2">
                      <label>&nbsp;</label>
                      <div class="d-flex align-items-center h-40">
                        <i class="material-symbols-outlined remove-icon"
                           title="Remove"
                           (click)="removeTaxExemption(idx)">
                          delete
                        </i>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <div class="col-12" [class.mt-n3]="form.get('taxExemptions')['controls']?.length">
                <button class="btn btn-tonal-primary rounded-sm py-2"
                        title="Add tax exemption"
                        (click)="addNewTaxExemption();">
                  <i class='fa fa-plus fs-10'></i>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer d-flex justify-content-center gap-12">
        <a
          class="btn btn-secondary mr-3"
          [routerLink]="['/presets']">
          Back
        </a>
        <button *ngIf="!isEdit" (click)="editPreset()" class="btn btn-primary float-right">Edit</button>
        <button
          *ngIf="unsavedChanges"
          class="btn btn-primary"
          (click)="save()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #helpIcon let-popoverText="popoverText" let-btnKey="btnKey">
  <span class="material-icons help-icon"
        (mouseenter)="onMouseEnter(btnKey)"
        (mouseleave)="onMouseLeave(btnKey)"
        [ngbPopover]="popoverText"
        [triggers]="'hover'"
        popoverClass="help-popover"
        placement="right"
        #popover="ngbPopover">
    {{buttonName[btnKey]}}
  </span>
</ng-template>
