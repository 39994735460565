import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {convertPhoneIntoString} from "../../shared/adapters/v1_2/adapters";
import {Process} from "../../shared/interfaces/show-process";
import {ErrorAlert} from "../../shared/models/error-alert";
import {HelpersService} from "../../shared/services/helpers.service";
import {SentryService} from "../../shared/services/sentry.service";
import {NDCApiService} from "../../shared/services/ndc-api.service";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import { PaymentModuleService } from '../../shared/services/payment-module.service';
import { ROLES } from '../../shared/constants';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import {fadeInOnEnterAnimation} from 'angular-animations';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pending-payments-modal',
  templateUrl: './pending-payments-modal.component.html',
  styleUrls: ['./pending-payments-modal.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 500, delay: 200 }),
  ],
})
export class PendingPaymentsModalComponent implements OnInit, OnDestroy {

  @Input() order;
  @Input() paymentMethodsExists: boolean;
  @Output() emitChangeSuccess = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @ViewChild('popover') popover: NgbPopover;

  ROLES = ROLES;
  step = 1;
  validateTriggered = false;
  pendingPaymentProcess: Process = {
    isProcess: false,
    processTitle: 'Payment...'
  };
  orderChangeError: ErrorAlert = new ErrorAlert();
  currency = '';
  totalPrice = 0;
  orderRepriceError: ErrorAlert = new ErrorAlert();
  orderRepriceProcess: Process = {
    isProcess: false,
    processTitle: 'Order reprice...'
  };
  orderRepriceAvailable = false;
  priceChanged: boolean;
  pendingPaymentsData: any;
  isPaymentInfoError = false;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  buttonName = 'info_outline';

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!event.target?.className?.includes('pax-info-icon')) {
      this.buttonName = 'info_outline';
    }
  }

  onMouseEnter() {
    this.buttonName = 'info';
  }

  onMouseLeave() {
    if (this.popover && !this.popover.isOpen()) {
      this.buttonName = 'info_outline';
    }
  }

  constructor(private helpers: HelpersService,
              private sentryService: SentryService,
              private ndcApiService: NDCApiService,
              protected paymentService: PaymentModuleService,
              public ls: LocalStorageService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getPendingPaymentsData();
  }

  getPendingPaymentsData() {
    const typesOfTickets = ['701', '702', 'T', 'VCR'];
    this.totalPrice = 0;
    this.pendingPaymentsData = [...this.order.issuingPendings];
    this.pendingPaymentsData.forEach(item => {
      if (item.type === 'rebook') {
        item.title = 'Order Rebook';
        const oldTicket = this.order.tickets?.find(ticket => typesOfTickets.includes(ticket.typeCode) && ticket.coupons?.every(coupon => coupon.segment));
        item.oldTicket = oldTicket;
        this.orderRepriceAvailable = true;
      } else if (item.type === 'seat') {
        item.title = 'Seat addition';
        const seat = this.order.seats?.find(seat => seat.listKey === item.reference);
        if (seat) {
          const passenger = this.order.passengers?.find(passenger => passenger.travelerReference === seat.passengerRefs);
          item.passenger = passenger;
          this.order.flights.forEach(flight => {
           const segment = flight.segments.find(segment => segment.segmentID === seat.segment);
           if (segment) {
             item.segment = segment;
           }
          });
          item.seat = seat;
        }
      } else if (item.type === 'service') {
        const service = this.order.services?.find(service => service.serviceID === item.reference);
        if (service) {
          item.title = service.name;
          const passenger = this.order.passengers?.find(passenger => passenger.travelerReference === service.travelerReferences);
          item.passenger = passenger;

          const segments = [];
          this.order.flights.forEach(flight => {
            flight.segments.forEach(segment => segments.push(segment));
          });
          const destinationData = {
            entireTrip: [],
            perLeg: {},
            perSegment: {}
          };
          this.helpers.splitServiceByDestinationType(service, segments, destinationData);

          let destinationType = '';
          if (destinationData.entireTrip.length) {
            destinationType = 'Entire trip';
          } else if (Object.keys(destinationData.perLeg).length) {
            destinationType = Object.keys(destinationData.perLeg)[0];
          } else if (Object.keys(destinationData.perSegment).length) {
            destinationType = Object.keys(destinationData.perSegment)[0];
          }
          item.destinationType = destinationType;
        }
      } else {
        item.title = item.type;
      }
      this.totalPrice += item.price.consumer.total;
      this.currency = item.price.consumer.currency;
    });
  }

  handleSecondaryButtonClick() {
    if (this.step === 1 || this.step === 4) {
      this.emitClose.emit();
    } else {
      this.step = this.step - 1;
    }
  }

  validatePayment() {
    this.validateTriggered = true;
    return this.paymentService.form.valid;
  }

  proceedPayment() {
    this.orderChangeError = new ErrorAlert();
    if (!this.validatePayment()) {
      return false;
    }
    this.step = 3;
  }

  orderChange() {
    const body = {
      id: this.order.id,
      payment: this.paymentService.getPaymentData(),
      action: "issue_pending_items_on_hold_mode"
    };
    if (body.payment && body.payment.phone) {
      body.payment.phone = convertPhoneIntoString(body.payment.phone);
    }

    this.pendingPaymentProcess.isProcess = true;
    this.cdr.detectChanges();

    this.ndcApiService.sendOrderChange(body, false)
      .then(response => {
        this.pendingPaymentProcess.isProcess = false;
        this.emitChangeSuccess.emit();
        this.step = 4;
      }).catch((error) => {
      this.pendingPaymentProcess.isProcess = false;
      this.orderChangeError = this.helpers.getError(error);
      this.sentryService.setAdditionalData(this.ndcApiService.lastSessionID, this.ndcApiService.lastRequestID, body, error.status, error);
      if (this.helpers.isCriticalError(error)) {
        throw error;
      }
    });
  }

  repriceOrder() {
    this.orderRepriceProcess.isProcess = true;
    this.orderRepriceError = new ErrorAlert();
    const body = {
      id: this.order.id,
    };

    this.ndcApiService.sendOrderReshopReprice(body)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        finalize(() => this.orderRepriceProcess.isProcess = false)
      )
      .subscribe({
        next: (response) => {
          const order = response.body;
          this.orderRepriceAvailable = false;
          this.handlePriceChange(order.price?.consumer?.total);
        },
        error: (error) => {
          this.orderRepriceError = this.helpers.getError(error);
        }
      });
  }

  private handlePriceChange(newPrice: number | undefined) {
    if (newPrice && newPrice !== this.totalPrice) {
      this.totalPrice = newPrice;
      this.priceChanged = true;
    } else {
      this.priceChanged = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
