<div class="modal-card card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title">Search Settings</h3>
      </div>
      <div class="col-auto">
        <button type="button" class="close" data-dismiss="modal" (click)="emitDismiss.emit()">×</button>
      </div>
    </div>
  </div>

  <div class="card-body modal-card-body-height">
    <div class="form-row justify-content-center">
      <div class="form-group-sm col-lg-4 col-md-5">
        <div class="d-flex align-items-center">
          <input
            id="enableLimitOfOffers"
            class="w-20 h-20 mr-3"
            type="checkbox"
            [(ngModel)]="enableLimitOfOffers">
          <label for="enableLimitOfOffers" class="mb-0">
            Enable limit of offers
          </label>
        </div>
      </div>
    </div>

    <div class="form-row justify-content-center" *ngIf="enableLimitOfOffers">
      <div class="form-group-sm col-lg-4 col-md-5">
        <label for="maxOffersPerCabin" class="d-flex align-items-center gap-10">
          Max offers per cabin
          <ng-container [ngTemplateOutlet]="helpIcon"
                        [ngTemplateOutletContext]="{popoverText: 'Set the maximum number of offers returned per cabin for each provider.', btnKey: 'enableLimitOfOffers'}">
          </ng-container>
        </label>
        <input
          id="maxOffersPerCabin"
          type="number"
          class="form-control"
          [(ngModel)]="maxOffersPerCabin">
      </div>
    </div>

    <div class="form-row justify-content-center">
      <div class="form-group-sm col-lg-4 col-md-5">
        <label for="preferredStopsDuringFlight">
          Preferred stops during flight
        </label>
        <select
          id="preferredStopsDuringFlight"
          class="form-control"
          [(ngModel)]="stopsAmount"
          (ngModelChange)="stopsAmount = $event ? Number($event) : null">
          <option value="0">Non-stop</option>
          <option value="1">1 Stop</option>
          <option value="10">2 Stops or More</option>
        </select>
      </div>
    </div>

    <div class="form-row justify-content-center">
      <div class="form-group-sm col-lg-4 col-md-5">
        <label for="searchMode" class="d-flex align-items-center gap-10">
          Search mode
          <ng-container [ngTemplateOutlet]="helpIcon"
                        [ngTemplateOutletContext]="{popoverText: searchModePopoverText, btnKey: 'searchMode'}">
          </ng-container>
        </label>
        <select
          id="searchMode"
          class="form-control"
          [(ngModel)]="searchMode">
          <option value="">None</option>
          <option *ngFor="let mode of searchModes | pairs" [value]="mode[0]">{{mode[1]}}</option>
        </select>
      </div>
    </div>

    <div class="form-row justify-content-center">
      <div class="form-group-sm col-lg-4 col-md-5">
        <label for="requestTimeout" class="d-flex align-items-center gap-10">
          Request timeout
          <ng-container [ngTemplateOutlet]="helpIcon"
                        [ngTemplateOutletContext]="{popoverText: 'Set how long do you wait for results, in seconds.', btnKey: 'requestTimeout'}">
          </ng-container>
        </label>
        <input
          id="requestTimeout"
          type="number"
          class="form-control"
          [(ngModel)]="requestTimeout">
      </div>
    </div>

    <div class="form-row justify-content-center">
      <div class="form-group-sm col-lg-4 col-md-5">
        <div class="d-flex align-items-center">
          <input
            id="avoidDisclosures"
            class="w-20 h-20 mr-3"
            type="checkbox"
            [(ngModel)]="avoidDisclosures">
          <label for="avoidDisclosures" class="d-flex align-items-center gap-10 mb-0">
            Avoid disclosures
            <ng-container [ngTemplateOutlet]="helpIcon"
                          [ngTemplateOutletContext]="{popoverText: 'Disclosures information is excluded from each offer for a lighter response.', btnKey: 'avoidDisclosures'}">
            </ng-container>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer text-right">
    <span
      (click)="emitDismiss.emit()"
      class="btn btn-secondary mr-3"
    >Close
    </span>
    <span
      (click)="saveSearchSettings(); emitClose.emit()"
      class="btn btn-primary"
    >Save
    </span>
  </div>
</div>

<ng-template #searchModePopoverText>
  <span>Choose how the search works for more precise results:</span>
  <ul class="mb-0 pl-5">
    <li><b>cheapest_flights:</b> Returns the lowest fares per airline.</li>
    <li><b>cheapest_flights_per_cabin:</b> Returns the lowest fares per cabin type.</li>
    <li><b>combine_same_fares_only:</b> Combines identical fares (e.g., Economy Basic Plus).</li>
    <li><b>cheapest_fare_with_baggage_only:</b> Returns the lowest fare with baggage.</li>
  </ul>
  <span>By default, all possible offers are combined.</span>
</ng-template>

<ng-template #helpIcon let-popoverText="popoverText" let-btnKey="btnKey">
  <span class="material-icons fs-16 black-color"
        (mouseenter)="updateButtonName(btnKey, 'help')"
        (mouseleave)="updateButtonName(btnKey, 'help_outline')"
        [ngbPopover]="popoverText"
        [triggers]="'hover'"
        container="body"
        popoverClass="help-popover"
        placement="right"
        #popover="ngbPopover">
    {{buttonName[btnKey]}}
  </span>
</ng-template>
