<div class="row pb-3 pt-4">
  <div *ngIf="!order.history?.length " class="col alert-warning m-3 p-3 rounded">
    Order order.history is empty
  </div>
  <div class="col-md-10" *ngIf="order.history && order.history.length">
    <div class="timeline" *ngFor="let h of order.history, let i = index; last as last" [ngClass]="{'line': !last || order.history.length === 1}">
      <div class="entry">
        <div class="title">
          <div class="date"
               *ngIf="h.eventTime && (h.eventTime | dateFormat: true : 'HH:mm') | async as date"
               [ngStyle]="{'margin-left': date.length < 16 ? '13px' : ''}">
            <app-date-with-popover
              [date]="h.eventTime">
            </app-date-with-popover>
          </div>
          <div class="icon-wrapper">
            <div class="outer-circle" *ngIf="!h.airlineNotification || h.airlineNotification?.seen">
              <div class="inner-circle"></div>
            </div>
            <i class="material-symbols-outlined exclamation-icon problem cursor-default" *ngIf="h.airlineNotification && !h.airlineNotification?.seen">
              exclamation
            </i>
          </div>
        </div>
        <div class="info" [style.margin]="last ? '0px' : '0 0 2em'">
          <div class="item">
            <span class="title">Action</span>
            <span>{{h.airlineNotification ? 'Airline Notification' : h.eventID}}</span>
          </div>

          <div class="item" *ngIf="h.agent">
            <span class="title">User</span>
            <span>{{ h.agent }}</span>
          </div>

          <div class="item" *ngIf="h.status">
            <span class="title">Status</span>
            <span>{{ h.status }}</span>
          </div>

          <div class="item" *ngIf="h.flights?.length && allowedActionsForItineraryDisplay.includes(h.eventID)">
            <span class="title"></span>
            <div class="d-flex flex-column gap-5">
              <div *ngFor="let flight of h.flights">
                <div *ngFor="let segment of flight.segments">
                  <div class="d-flex flex-wrap gap-7">
                    <div class="d-flex gap-3" style="min-width: 80px;">
                      <span [appTooltip]="{airportCode: segment.originDestination.departure?.airportCode, terminalName: segment.originDestination.departure?.terminalName}">
                        {{segment.originDestination.departure.airportCode}}
                      </span>
                      <span>→</span>
                      <span [appTooltip]="{airportCode: segment.originDestination.arrival?.airportCode, terminalName: segment.originDestination.arrival?.terminalName}">
                        {{segment.originDestination.arrival.airportCode}}
                      </span>
                    </div>

                    <span>
                      {{(segment.originDestination.departure.date | dateFormat) | async}} {{segment.originDestination.departure.time}} - {{(segment.originDestination.arrival.date | dateFormat) | async}} {{segment.originDestination.arrival.time}}
                    </span>

                    <span *ngIf="segment.flightNumber">
                      {{segment.flightNumber}}
                    </span>

                    <span *ngIf="segment.detail.classOfService?.code" class="mw-10">
                      {{segment.detail.classOfService.code}}
                    </span>

                    <span *ngIf="segment.detail.classOfService?.fare?.marketingName">
                      {{segment.detail.classOfService.fare.marketingName}}
                    </span>

                    <span *ngIf="segment.equipment?.aircraftCode">
                      {{segment.equipment.aircraftCode}}
                    </span>

                    <span *ngIf="segment.detail?.segmentType">
                      {{segment.detail.segmentType}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="item" *ngIf="h.eventID === 'OrderCanceled' && h.transactionType">
            <span class="title">Type</span>
            <span>{{h.transactionType}}</span>
          </div>

          <ng-container *ngIf="h.eventID == 'OrderDisrupted'">
            <div class="item">
              <span class="title">Type</span>
              <span>{{ h.details && h.details.code ? h.details.code : 'ScheduledTimeChange' }}</span>
            </div>
          </ng-container>

          <div class="item" *ngIf="['OrderTicketed', 'OrderCanceled'].indexOf(h.eventID) !== -1 && h.price">
            <span class="title">Price</span>
            <span>{{ h.price?.consumer?.total }} {{ h.price?.consumer?.currency }}</span>
          </div>

          <ng-container *ngIf="h.details && h.details.type">
            <div class="item" *ngIf="h.details.type !== h.eventID">
              <span class="title">Type</span>
              <span>{{ h.details.type }}</span>
            </div>

            <div class="item">
              <span class="title">Price</span>
              <span>{{ h.details.totalAmount }} {{ h.details.totalAmountCurrency }}</span>
            </div>

            <div class="item" *ngIf="['SeatsAddition', 'SeatsRemoval'].indexOf(h.details.type) !== -1">
              <span class="title">Items</span>
              <span>
                <ul *ngFor="let item of h.details.items;">
                  <li>
                    <span class="midnight-color"><b>Seat:</b></span>
                    {{item.seats[0]}} for {{item.segmentRef}}
                    <br>
                    <span class="midnight-color"><b>Passengers:</b></span>
                     <ng-container *ngFor="let passenger of item.passengers; last as last">
                      {{passenger}}{{!last ? ', ' : ''}}
                    </ng-container>
                  </li>
                </ul>
              </span>
            </div>

            <div class="item" *ngIf="h.details.changedRouteDetails">
              <span class="title">Changed</span>
              <span>
                <ul>
                  <li *ngFor="let route of h.details.changedRouteDetails;">
                    <span class="midnight-color"><b>Itinerary: </b></span> {{ route.departure.airportCode + ' → ' + route.arrival.airportCode }}
                    <br>
                    <span class="midnight-color"><b>Departure Date: </b></span> {{(route.departure.date | dateFormat) | async}},
                    <span class="midnight-color"><b>Time: </b></span> {{route.departure.time}}
                    <br>
                    <span class="midnight-color"><b>Arrival Date: </b></span> {{(route.arrival.date | dateFormat) | async}},
                    <span class="midnight-color"><b>Time: </b></span> {{route.arrival.time}}
                  </li>
                </ul>
              </span>
            </div>

            <ng-container *ngIf="h.details.type == 'DatesChange'">
              <div class="item">
                <span class="title">Type</span>
                <span>{{ h.details.type }}</span>
              </div>

              <div class="item">
                <span class="title">New</span>
                <span>
                  <ul>
                    <li *ngFor="let route of h.details.changedRouteDetails;">
                      <span class="midnight-color"><b>{{ route.departure.airportCode }} → {{ route.arrival.airportCode}}</b></span>
                      - {{(route.departure.date | dateFormat) | async}} {{route.departure.time}}
                    </li>
                  </ul>
                </span>
              </div>
            </ng-container>

            <div class="item" *ngIf="['SeatsAddition', 'SeatsRemoval', 'ItineraryChange'].indexOf(h.details.type) === -1">
              <span class="title">Items</span>
              <div>
                <ul *ngFor="let item of h.details.items;">
                  <li>
                    <span class="midnight-color"><b>Name:</b></span> {{ item.name }}
                    <br>
                    <span class="midnight-color"><b>Passengers:</b></span>
                    <ng-container *ngFor="let passenger of item.passengers; last as last">
                      {{passenger}}{{!last ? ', ' : ''}}
                    </ng-container>
                    <br>
                    <span class="midnight-color"><b>Details: </b></span>
                    <span class="d-inline-flex flex-column">
                      <span *ngFor="let description of item.descriptions">
                        <ng-container *ngIf="h.eventID === 'OrderSplit'">
                          {{getDescriptionDetails(description).order}}:
                          <a class="red-color" (click)="openInNewTab(getDescriptionDetails(description).link)">{{getDescriptionDetails(description).id}}</a>
                        </ng-container>
                        <ng-container *ngIf="h.eventID !== 'OrderSplit'">
                          {{description}}
                        </ng-container>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="h.details && h.details.flightDetailsDiff?.length">
            <ng-container *ngFor="let diff of h.details.flightDetailsDiff; let idx = index">
              <div class="item">
                <span class="title">
                  <ng-container *ngIf="!h.details.flightDetailsDiff[idx-1] || h.details.flightDetailsDiff[idx]?.type !== h.details.flightDetailsDiff[idx-1]?.type ">
                    {{diff.type | capitalizeFirstLetter}}
                  </ng-container>
                </span>
                <div class="d-flex flex-column gap-5">
                  <ng-container [ngTemplateOutlet]="flightDetails"
                                [ngTemplateOutletContext]="{ flight: diff.old, diffs: diff.diffs, type: 'old', diffType: diff.type }">
                  </ng-container>
                  <ng-container [ngTemplateOutlet]="flightDetails"
                                [ngTemplateOutletContext]="{ flight: diff.new, diffs: diff.diffs, type: 'new', diffType: diff.type }">
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="item" *ngIf="h.tickets && h.tickets.length">
            <span class="title">Tickets</span>
            <span>
              <ul *ngFor="let ticket of h.tickets">
                <li>
                  <span class="midnight-color">
                    <b>{{ticket.ticketNumber | getTicketNumber}}</b>
                  </span> - {{ ticket.typeCodeDefinition }} for {{ ticket.passengerDetails }}
                </li>
              </ul>
            </span>
          </div>

          <div class="item" *ngIf="h.tickets && hasExchangedTickets(h.tickets)">
            <span class="title">Exchanged from</span>
            <span>
              <ng-container *ngFor="let ticket of h.tickets">
                <ul *ngIf="ticket.ticketExchangedNumber">
                  <li>
                    <span class="midnight-color">
                      <b>{{ticket.ticketExchangedNumber | getTicketNumber}}</b>
                    </span> - {{ ticket.typeCodeDefinition }} for {{ getPassengersNameByRef(ticket.passengerRef) | joinArrayPipe }}
                  </li>
                </ul>
              </ng-container>
            </span>
          </div>

          <ng-container *ngIf="h.airlineNotification">
            <ng-container *ngFor="let amendment of h.airlineNotification.amendments">
              <div class="item">
                <span class="title">Type</span>
                <span>{{amendment.type}}</span>
              </div>
              <div class="item" *ngIf="amendment.context || amendment.contextMeaning">
                <span class="title">Context</span>
                <span>
                  <ng-container *ngIf="amendment.contextMeaning">
                    {{amendment.contextMeaning}}
                  </ng-container>
                  <ng-container *ngIf="!amendment.contextMeaning">
                    {{amendment.context | getDisruptionDescription}}
                  </ng-container>
                </span>
              </div>
              <div class="item" *ngIf="amendment.remarks?.length">
                <span class="title">Remarks</span>
                <span>
                   <ul *ngFor="let remark of amendment.remarks">
                    <li>
                      {{remark}}
                    </li>
                   </ul>
                </span>
              </div>
              <div class="item" *ngIf="h.airlineNotification.seen">
                <span class="title">Seen by</span>
                <span>{{h.airlineNotification.seenBy}}</span>
              </div>
            </ng-container>
            <div class="mt-3">
              <app-notification-seen-checkbox
                [checked]="h.airlineNotification.seen"
                (emitNotificationChange)="onNotificationChange(h.airlineNotification)">
              </app-notification-seen-checkbox>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>

  <div class="col-md-2" *ngIf="order.history?.length > 1">
    <button class="btn sort-btn" (click)="toggleSort()">
      Date
      <i class="material-symbols-outlined" [@rotate]="isDefaultSort">
        arrow_upward
      </i>
    </button>
  </div>
</div>

<ng-template #flightDetails let-flight="flight" let-diffs="diffs" let-type="type" let-diffType="diffType">
  <ng-container *ngIf="flight">
    <div class="d-flex gap-7">
      <div *ngIf="diffType === 'modified'">{{type | uppercase}}: </div>
      <div class="d-flex flex-wrap gap-7" [ngClass]="type === 'old' ? 'red-color' : 'dark-green-color'">
        <div class="d-flex gap-3 mw-80">
          <span [appTooltip]="{airportCode: flight.departure?.airportCode, terminalName: flight.departure?.terminalName}">
            {{flight.departure.airportCode}}
          </span>
          <span>→</span>
          <span [appTooltip]="{airportCode: flight.arrival?.airportCode, terminalName: flight.arrival?.terminalName}">
            {{flight.arrival.airportCode}}
          </span>
        </div>

        <span class="d-flex gap-3">
          <span [class.fw-500]="diffs?.includes('departure.date')">{{(flight.departure.date | dateFormat) | async}}</span>
          <span [class.fw-500]="diffs?.includes('departure.time')">{{flight.departure.time}}</span> -
          <span [class.fw-500]="diffs?.includes('arrival.date')">{{(flight.arrival.date | dateFormat) | async}}</span>
          <span [class.fw-500]="diffs?.includes('arrival.date')">{{flight.arrival.time}}</span>
        </span>

        <span *ngIf="flight.flightNumber" [class.fw-500]="diffs?.includes('flight_number')">
          {{flight.flightNumber}}
        </span>

        <span *ngIf="flight?.cabinDesignator" class="mw-80" [class.fw-500]="diffs?.includes('cabin_designator')">
          {{flight.cabinDesignator}}
        </span>

        <span *ngIf="flight?.classCode" class="mw-24" [class.fw-500]="diffs?.includes('class_code')">
          {{flight.classCode}}
        </span>

        <span *ngIf="flight?.aircraftCode" class="mw-30" [class.fw-500]="diffs?.includes('aircraft_code')">
          {{flight.aircraftCode}}
        </span>

        <span *ngIf="flight?.status" [class.fw-500]="diffs?.includes('status')">
          {{flight.status}}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #helpPopover>
  <div class="d-flex gap-3">
    <span>Mark as seen to hide warning and</span>
    <i class="material-symbols-outlined exclamation-icon problem">
      exclamation
    </i>
    <span>for this notification</span>
  </div>
</ng-template>
