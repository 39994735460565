import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popover-wrapper',
  templateUrl: './popover-wrapper.component.html',
  styleUrls: ['./popover-wrapper.component.scss']
})
export class PopoverWrapperComponent implements OnInit {
  @Input() popoverContent: string | TemplateRef<any> = '';
  @Input() disablePopover = false;
  @Input() popoverClass = 'popover-wp';
  @Input() triggers = 'hover';
  @Input() additionalClasses = 'd-inline-block ml-3';

  constructor() { }

  ngOnInit(): void {
  }

}
