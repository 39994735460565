export const AMADEUS_SEARCH = {
  AVAILABILITY_TYPES: ['A', 'D', 'N'],
};

export const SEARCH_TYPE = {
  REGULAR: 'REGULAR',
  GDS: 'GDS',
  PNR: 'PNR'
};

export const OFFER_TAB = {
  PER_LEG: 'Per leg',
  FULL_TRIP: 'Full trip',
};

export const ALL_RESET_KEYS_OBJ = {
  'origin_destinations-flights': undefined,
  'max_stops': 10,
  'arrival_time_range': undefined,
  'origin_destinations_flightnumbers': undefined,
  'selected_table_cells': 'ALL',
  'selected_table_sort_value': 'price',
  'departure_airports': undefined,
  'arrival_airports': undefined,
  'connections_white_list': undefined,
  'connections_black_list': undefined,
  'departure_time_range': undefined,
  'max-flight-duration': undefined,
  'operating_airline': undefined,
  'selected_checked_baggage': undefined,
  'selected_fare_rules': undefined,
  'checked_baggage_included': undefined
};
