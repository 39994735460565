import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
  SimpleChanges
} from '@angular/core';
import { OfferService } from '../../../shared/services/offer.service';
import { SimpleOffer } from '../../interfaces/offer';
import {LocalStorageService} from "../../services/local-storage.service";
import {rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
  ]
})
export class OptionsComponent implements OnInit, OnChanges {

  @Input() simpleOffers: SimpleOffer[];
  @Input() desireOffer?: any;
  @Input() showOptions = false;
  @Input() allOffers = [];
  @Input() isReshop;
  @Input() showLoader = false;
  @Input() columnsToShow: {action: boolean} = {
    action: true
  };
  @Input() isOfferExpired = false;

  @Output() onSelectedOffer = new EventEmitter();
  @ViewChildren('row') rows: QueryList<any>;

  groupNames = [];

  isCollapsed = {};

  // changeShowAllAppropriateOffersFlag = true;

  constructor(
    public ls: LocalStorageService,
    private offerService: OfferService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.simpleOffers) {
      this.simpleOffers = this.offerService.sortOffersByPrice(this.simpleOffers);
    }
  }

  setSelectedOffer(offerID, isOpenNewWindow = false) {
    this.onSelectedOffer.emit({ offerID, isOpenNewWindow });
  }

  private updateHoverClass(rowIndex: number, index: number, action: 'add' | 'remove', hoverClass: string): void {
    const rowElement = this.rows.get(rowIndex);

    if (rowElement) {
      const method = action === 'add' ? 'add' : 'remove';
      rowElement.nativeElement.classList[method](hoverClass);
    }
  }

  private handleFares(fares: any[], i: number, action: 'add' | 'remove'): void {
    const faresLength = fares?.length;

    if (faresLength && faresLength > 1) {
      fares.forEach((flight, index) => {
        const rowIndex = i * faresLength + index;
        const isFirst = index === 0;
        const isLast = index === faresLength - 1;

        this.updateHoverClass(rowIndex, index, action, isFirst ? 'hovered-row-first' : (isLast ? 'hovered-row-last' : 'hovered-row-middle'));
      });
    } else {
      this.updateHoverClass(i, 0, action, 'hovered-row-single');
    }
  }

  mouseOver(i: number): void {
    const fares = this.simpleOffers[i]?.fares;
    this.handleFares(fares, i, 'add');
  }

  mouseLeave(i: number): void {
    const fares = this.simpleOffers[i]?.fares;
    this.handleFares(fares, i, 'remove');
  }

  collapse(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }
}
