import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-popover-button',
  templateUrl: './popover-button.component.html',
  styleUrls: ['./popover-button.component.scss']
})
export class PopoverButtonComponent implements OnInit {
  @Input() popoverContent!: string | TemplateRef<any>;
  @ViewChild('popover') popover: NgbPopover;

  buttonName = 'info_outline';

  @HostListener('click') onClick() {
    if (this.popover && !this.popover.isOpen()) {
      this.popover.open();
    }
  }

  @HostListener('mouseover') onMouseEnter() {
    this.buttonName = 'info';
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.popover && !this.popover.isOpen()) {
      this.buttonName = 'info_outline';
    }
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.buttonName = 'info_outline';
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

}
