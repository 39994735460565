import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getOrderStatus'
})
export class GetOrderStatusPipe implements PipeTransform {

  transform(status: string, provider: string): unknown {
    switch (status) {
      case 'Pending':
        return provider !== 'FR' && provider !== 'U2' ? 'On Hold' : status;
      default:
        return status;
    }
  }

}
