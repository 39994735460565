import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {OrderRoutingModule} from "./order-routing";
import {OrderComponent} from "./order.component";
import {OrderDetailComponent} from "./order-detail/order-detail.component";
import {OrderChangeComponent} from "./order-change/order-change.component";
import {OrderListComponent} from "./order-list/order-list.component";
import {SharedModule} from "../shared/shared.module";
import {CommentsComponent} from "./order-detail/comments/comments.component";
import { HistoryComponent } from './order-detail/history/history.component';
import {ServiceListModalComponent} from "./order-detail/service-list-modal/service-list-modal.component";
import {SelectSeatsModalComponent} from "./order-detail/select-seats-modal/select-seats-modal.component";
import {PDFExportModule} from "@progress/kendo-angular-pdf-export";
import { MetasComponent } from './order-detail/metas/metas.component';
import { OrderSplitModalComponent } from './order-split-modal/order-split-modal.component';
import {DashboardModule} from "../dashboard/dashboard.module";
import { OrderLogComponent } from './order-log/order-log.component';
import { AirlineNotificationModalComponent } from './airline-notification-modal/airline-notification-modal.component';
import {OrderSearchService} from "../shared/services/order-search.service";
import {WindowRef} from "../shared/services/window-ref.service";
import {PnrGeneratorService} from "../dashboard/services/pnr-generator.service";
import { PendingPaymentsModalComponent } from './pending-payments-modal/pending-payments-modal.component';
import { OrderExpirationComponent } from './order-detail/order-expiration/order-expiration.component';
import { OsiDetailsComponent } from './order-detail/osi-details/osi-details.component';
import { CorporateInfoComponent } from './order-detail/corporate-info/corporate-info.component';


@NgModule({
  imports: [
    OrderRoutingModule,
    SharedModule,
    PDFExportModule,
    DashboardModule,
  ],
  declarations: [
    OrderComponent,
    OrderDetailComponent,
    OrderChangeComponent,
    CommentsComponent,
    HistoryComponent,
    ServiceListModalComponent,
    SelectSeatsModalComponent,
    OrderListComponent,
    MetasComponent,
    OrderSplitModalComponent,
    OrderLogComponent,
    AirlineNotificationModalComponent,
    PendingPaymentsModalComponent,
    OrderExpirationComponent,
    OsiDetailsComponent,
    CorporateInfoComponent,
  ],
  providers: [
    OrderSearchService,
    PnrGeneratorService,
    WindowRef
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class OrderModule {

}
