<div class="container mt-3">
  <div *ngIf="order" class="breadcrumbs mb-3">
    <div class="orders" (click)="helpers.go('/orders')">
      <span>Orders</span>
      <span class="material-symbols-outlined navigate-next-icon">
        navigate_next
      </span>
    </div>
    <span class="order-id">{{ order.id }}</span>
  </div>
  <div class="card order-card position-relative" *ngIf="order">
    <div class="card-header order-card-header" *ngIf="!orderRetrieveError.message">
      <div class="row card-header_topbar"> <!--check -->
        <div class="col-lg-9">
          <ul class="nav nav-tabs card-header-tabs align-items-center">
            <li class="mr-4">
              <div *ngIf="owner" class="rounded main-owner-logo">
                <app-airline-img
                  [airlineID]="owner"
                  [title]="owner | getImageDescription: order.flights"
                ></app-airline-img>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 [class.active]="selectedTab === orderTab.OrderDetails"
                 (click)="selectTab(orderTab.OrderDetails)">
                Details
              </a>
            </li>
            <li class="nav-item" *ngIf="viewOpts.orderHistory">
              <a class="nav-link"
                 [class.active]="selectedTab === orderTab.OrderHistory"
                 (click)="selectTab(orderTab.OrderHistory)">
                <span class="d-flex align-items-center" (click)="onWarnIconChange('order_history', order.status)">
                  History
                  <i *ngIf="tabsWarnIconVisibility.order_history && order.history?.length > 1" class="material-icons error-icon">
                    error_outline
                  </i>
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="viewOpts.comments">
              <a class="nav-link"
                 [class.active]="selectedTab === orderTab.Comments"
                 (click)="selectTab(orderTab.Comments)">
                <span class="d-flex align-items-center" (click)="onWarnIconChange('comments', order.status)">
                  Comments
                  <i *ngIf="tabsWarnIconVisibility.comments && order.comments.length" class="material-icons error-icon">
                    error_outline
                  </i>
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="viewOpts.remarks">
              <a class="nav-link"
                 [class.active]="selectedTab === orderTab.Remarks"
                 (click)="selectTab(orderTab.Remarks)">
                <span class="d-flex align-items-center">
                  Remarks
                  <i *ngIf="isRemarksIconVisible && order.status === ORDER_STATUS.PENDING && !isRemarksFilled"
                     class="material-icons error-icon">
                    error_outline
                  </i>
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="viewOpts.metas">
              <a class="nav-link"
                 [class.active]="selectedTab === orderTab.Metas"
                 (click)="selectTab(orderTab.Metas)">
                <span class="d-flex align-items-center" (click)="onWarnIconChange('metas', order.status)">
                  Metas
                  <i *ngIf="tabsWarnIconVisibility.metas" class="material-icons error-icon">
                    error_outline
                  </i>
                </span>
              </a>
            </li>
            <li class="nav-item" *ngIf="viewOpts.orderLog">
              <a class="nav-link"
                 [class.active]="selectedTab === orderTab.OrderLog"
                 (click)="selectTab(orderTab.OrderLog)">
                <span class="d-flex align-items-center" (click)="onWarnIconChange('order_log', order.status)">
                  Log
                  <i *ngIf="tabsWarnIconVisibility.order_log" class="material-icons error-icon">
                    error_outline
                  </i>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 header-actions">
          <button class="btn btn-tonal-primary"
                  (click)="loadOrder(true, 'refreshOrderButtonPress');"
                  title="Refresh Order">
            <i class="material-symbols-outlined">refresh</i>
          </button>
          <div class="dropdown card-header-dropdown" ngbDropdown placement="bottom-right" autoClose="outside" #cardHeaderDropdown="ngbDropdown">
            <button class="btn menu-btn card-header-dropdown-toggle" ngbDropdownToggle>
              <i class="material-symbols-outlined">
                {{ cardHeaderDropdown.isOpen() ? 'close' : 'dehaze' }}
              </i>
            </button>
            <div class="card-header-dropdown-menu" ngbDropdownMenu>
              <div class="exports-dropdown" ngbDropdown autoClose="outside">
                <div class="dropdown-item" ngbDropdownToggle>
                  <div class="d-flex gap-5">
                    <i class="material-symbols-outlined">download</i>Exports
                  </div>
                </div>
                <div class="exports-dropdown-menu" ngbDropdownMenu>
                  <div class="dropdown-item">
                    <app-export-to-pnr [order]="order" [popover]="cardHeaderDropdown" [isBtn]="false"></app-export-to-pnr>
                  </div>
                  <div *ngIf="viewOpts.gds" class="gds-dropdown dropright" ngbDropdown autoClose="outside" placement="right-top">
                    <div class="dropdown-item" ngbDropdownToggle>
                      <ul class="mb-0"><li>GDS</li></ul>
                    </div>
                    <div class="gds-dropdown-menu" ngbDropdownMenu>
                      <div class="dropdown-item" (click)="openAmadeusPassiveSegmentModal(amadeusPassiveSegmentModal); cardHeaderDropdown.close()">
                        Amadeus
                      </div>
                      <div class="dropdown-item" (click)="openSabrePassiveSegmentModal(sabrePassiveSegmentModal); cardHeaderDropdown.close()">
                        Sabre
                      </div>
                      <div class="dropdown-item" (click)="openTravelportPassiveSegmentModal(travelportPassiveSegmentModal); cardHeaderDropdown.close()">
                        Travelport
                      </div>
                    </div>
                    <ng-template #amadeusPassiveSegmentModal let-c="close" let-d="dismiss">
                      <div class='pnr-modal-body'>
                        <label>Original:</label>
                        <textarea class="text-area-mask" [(ngModel)]="originalAmadeusPassiveSegment" style="height: 130px;" readonly></textarea>
                        <label>Anonymized:</label>
                        <textarea class="text-area-mask" [(ngModel)]="anonymizedAmadeusPassiveSegment" style="height: 100px;" readonly></textarea>
                        <div class="modal-bottom">
                          <button class="btn btn-secondary" (click)="d('closed'); clearPassiveSegment()">Close</button>
                          <div>
                            <button class="btn btn-outline-primary mr-3" [cdkCopyToClipboard]="originalAmadeusPassiveSegment" (click)="clearPassiveSegment(); c('ok')">Copy Original GDS</button>
                            <button class="btn btn-outline-primary" [cdkCopyToClipboard]="anonymizedAmadeusPassiveSegment" (click)="clearPassiveSegment(); c('ok')">Copy Anonymized GDS</button>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <ng-template #sabrePassiveSegmentModal let-c="close" let-d="dismiss">
                      <div class='pnr-modal-body'>
                        <label>Original:</label>
                        <textarea class="text-area-mask" [(ngModel)]="originalSabrePassiveSegment" style="height: 100px;" readonly></textarea>
                        <label>Anonymized:</label>
                        <textarea class="text-area-mask" [(ngModel)]="anonymizedSabrePassiveSegment" style="height: 100px;" readonly></textarea>
                        <div class="modal-bottom">
                          <button class="btn btn-secondary" (click)="d('closed'); clearPassiveSegment()">Close</button>
                          <div>
                            <button class="btn btn-outline-primary mr-3" [cdkCopyToClipboard]="originalSabrePassiveSegment" (click)="clearPassiveSegment(); c('ok')">Copy Original GDS</button>
                            <button class="btn btn-outline-primary" [cdkCopyToClipboard]="anonymizedSabrePassiveSegment" (click)="clearPassiveSegment(); c('ok')">Copy Anonymized GDS</button>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <ng-template #travelportPassiveSegmentModal let-c="close" let-d="dismiss">
                      <div class='pnr-modal-body'>
                        <textarea class="text-area-mask" [(ngModel)]="travelportPassiveSegment" style="height: 130px;" readonly></textarea>
                        <div class="modal-bottom">
                          <button class="btn btn-secondary" (click)="d('closed'); clearPassiveSegment()">Close</button>
                          <button class="btn btn-outline-primary" [cdkCopyToClipboard]="travelportPassiveSegment" (click)="clearPassiveSegment(); c('ok')">Copy GDS</button>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <div *ngIf="viewOpts.files"
                       class="files-dropdown dropright" ngbDropdown autoClose="outside" placement="right-top">
                    <div class="dropdown-item" ngbDropdownToggle>
                      <ul class="mb-0"><li>Files</li></ul>
                    </div>
                    <div class="files-dropdown-menu" ngbDropdownMenu>
                      <div class="dropdown-item"
                           (click)="generateFiles('air', cardHeaderDropdown)">
                        AIR
                      </div>
                      <div class="dropdown-item"
                           (click)="generateFiles('mir', cardHeaderDropdown)">
                        MIR
                      </div>
                      <div class="dropdown-item"
                           (click)="generateFiles('galor', cardHeaderDropdown)">
                        Galor
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pdf-dropdown" ngbDropdown autoClose="outside">
                <div class="dropdown-item" ngbDropdownToggle>
                  <div class="d-flex gap-5">
                    <i class="material-symbols-outlined">draft</i>PDF
                  </div>
                </div>
                <div class="pdf-dropdown-menu" ngbDropdownMenu>
                  <div *ngIf="viewOpts.legacyPdf" class="gds-dropdown dropright" ngbDropdown autoClose="outside" placement="right-top">
                    <div class="dropdown-item" ngbDropdownToggle>
                      <ul class="mb-0"><li>Legacy PDF</li></ul>
                    </div>
                    <div class="gds-dropdown-menu" ngbDropdownMenu>
                      <ng-container [ngTemplateOutlet]="pdfPopUp"></ng-container>
                      <ng-template #pdfPopUp>
                        <div class="pdf-wp-pop-up">
                          <div class="pdf-line" *ngFor="let option_key of pdfShowOptions | keys"
                               [class.passengers-group]="pdfShowOptions[option_key]['group']">
                            <input type="checkbox" [id]="option_key" [(ngModel)]="pdfShowOptions[option_key]['isShow']"
                                   (change)="onChangePassengersData(option_key, $event)">
                            <label class="mb-0" [for]="option_key">{{pdfShowOptions[option_key]['label']}}</label>
                          </div>
                          <div class="pdf-line" [class.disable-pdf]="!isAllowPdf" (click)="generatePDF(MYPDF); cardHeaderDropdown.close()">
                            Download PDF
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="dropdown-item"
                       [class.disabled]="showOrderPdfLoader"
                       (click)="downloadPDF(cardHeaderDropdown)">
                    <ul class="mb-0">
                      <li>
                        <span class="d-flex gap-5">
                          Download Itinerary
                          <div class="loader-wrapper-small mh-0 ml-4" *ngIf="showOrderPdfLoader">
                            <app-loader></app-loader>
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div *ngIf="frontendSelectedDependency.showSearch && viewOpts.search"
                   class="dropdown-item"
                   (click)="actionSearchAgain();">
                <div class="d-flex gap-5">
                  <i class="material-symbols-outlined">travel</i>Book Again
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body order-card-body pb-0" id="contentToConvert" [class.pdf-body]="isPDFExtract">
      <div class="row mb-3">
        <div class="orderInfo col-lg-12">
          <kendo-pdf-export #MYPDF paperSize="A4" margin="0.75cm" [scale]="scale">
            <div class="pdf-header" *ngIf="isPDFExtract">
              <img [src]="agencyImage"
                   (error)="agencyImage = defaultBookingPadLogo"
                   class="agency-pdf-logo">
              <div class="pdf-h-content">
                <div class="order-id">
                  <h2><span>Order: </span>{{ order.id }}</h2>
                </div>
              </div>
              <div class="pdf-h-overlay"></div>
            </div>
            <div class="tab-content" [ngSwitch]="selectedTab">
              <div class="tab-pane fade show active" *ngSwitchCase="orderTab.OrderDetails">
                <div>
                  <ng-container [ngTemplateOutlet]="pendingPaymentsWarning"></ng-container>
                  <ng-container [ngTemplateOutlet]="airlineNotificationAlerts"></ng-container>
                  <div class="mt-3" *ngIf="order.error">
                    <error-alert-block
                      [error]="{message: (order.error | capitalizeFirstLetter), type: 'danger-provider'}"
                    ></error-alert-block>
                  </div>
                  <div class="mt-3" *ngIf="orderCreateError.message">
                    <error-alert-block
                      [error]="orderCreateError"
                    ></error-alert-block>
                  </div>
                  <div class="mt-3" *ngIf="completed !== null && !isPDFExtract">
                    <div class="alert alert-success mb-3">
                      Booking has been completed. <br> Order ID: <span
                      class="font-weight-bold">{{ order.id }}</span>
                    </div>
                  </div>
                  <div class="mt-3" *ngIf="orderWarnings?.length || orderRetrieveWarnings?.length || providerRemarks?.length">
                    <div class="alert alert-warning mb-3 rounded warnings"
                         *ngIf="showWarnings"
                         [class.faded]="!allWarningsAreShown && !isPDFExtract"
                         [ngStyle]="{'max-height': !allWarningsAreShown && !isPDFExtract ? '190px' : 'fit-content' }"
                         #warnings>
                      <ul>
                        <li *ngFor="let warn of orderWarnings">
                          {{warn}}
                        </li>
                      </ul>
                      <ul>
                        <li *ngFor="let warn of orderRetrieveWarnings">
                          {{warn}}
                        </li>
                      </ul>
                      <ng-container *ngIf="providerRemarks?.length">
                        <h4 [class.mt-3]="orderWarnings?.length || orderRetrieveWarnings?.length" class="black-color">Provider remarks</h4>
                        <ul>
                          <li *ngFor="let warn of providerRemarks">
                            {{warn}}
                          </li>
                        </ul>
                      </ng-container>
                    </div>
                    <div class="d-flex justify-content-end mb-3" *ngIf="!isPDFExtract">
                      <div class="btn btn-secondary p-2"
                           [class.mr-3]="isWarningsReadBtnNeeded && showWarnings"
                           (click)="toggleDisplayWarnings()">
                        {{showWarnings ? 'Hide' : orderWarnings?.length || orderRetrieveWarnings?.length ? 'Show warnings' : 'Show remarks'}}
                      </div>
                      <div class="btn btn-secondary p-2"
                           *ngIf="isWarningsReadBtnNeeded && showWarnings"
                           (click)="toggleReadWarnings()">
                        Read {{!allWarningsAreShown ? 'more' : 'less'}}
                      </div>
                    </div>
                  </div>

                  <div class="row pb-5" *ngIf="pdfShowOptions.tableInfo.isShow || !isPDFExtract">
                    <div *ngIf="order.flights" class="col order-table-wp">
                      <table class="table table-default order-table" [ngClass]="{'pdf-table': isPDFExtract, 'opened': isOrderTableOpened}">
                        <thead>
                        <tr>
                          <th *ngIf="fromSubagencies">Agency</th>
                          <th style="width: 145px;">PNR</th>
                          <th>PAX</th>
                          <th>Booking Date</th>
                          <th>Type</th>
                          <th style="width: 210px;">O&D</th>
                          <th>Departure Date</th>
                          <th>Status</th>
                        </tr>
                        </thead>
                        <tbody [@fadeIn]="isOrderTableOpened">
                        <tr [@fadeIn]="!isOrderTableOpened">
                          <td *ngIf="fromSubagencies">
                            <div [ngbPopover]="order.agent"
                                 [triggers]="'hover'"
                                 placement="top"
                                 popoverClass="popover-wp"
                                 class="cursor-default">
                              {{ order.agency }}
                            </div>
                          </td>
                          <td class="select-none" [class.text-center]="isOrderTableOpened">
                            <span class="select-all">{{ order.pnr }}</span>
                            <app-show-alternative-ids
                              *ngIf="order.bookingReference?.alternativeIDs?.length && !isPDFExtract"
                              [alternativeIDs]="order.bookingReference.alternativeIDs"
                              [isOpened]="isOrderTableOpened">
                            </app-show-alternative-ids>
                          </td>
                          <td>
                            <ng-container *ngIf="!isPDFExtract; else passengersInfoPDF">
                              <ng-container *ngFor="let passenger of order.passengers; first as first">
                                <div class="d-flex align-items-center justify-content-center gap-5 text-nowrap">
                                  <ng-container *ngIf="!isOrderTableOpened && first">
                                    <span>({{passenger.passengerType}})</span>
                                    <span class="passenger-name">{{passenger.data.name}} {{passenger.data.surname}}</span>
                                    <span class="travelers-count" *ngIf="order.passengers?.length > 1">+ {{order.passengers?.length - 1}}</span>
                                  </ng-container>
                                  <ng-container *ngIf="isOrderTableOpened">
                                    <span>({{passenger.passengerType}})</span>
                                    <span class="passenger-name">{{passenger.data.name}} {{passenger.data.surname}}</span>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-template #passengersInfoPDF>
                              <div class="pax-info-tooltip-wp">
                                {{order.passengers.length}}
                              </div>
                            </ng-template>
                          </td>
                          <td style="white-space: nowrap;">
                            <ng-container *ngIf="order.bookingDate">
                              {{ (order.bookingDate | dateFormat) | async }}
                            </ng-container>
                          </td>
                          <td class="booking-type-icon">
                            <ng-container *ngIf="!isPDFExtract; else bookingTypeTitle" [ngSwitch]="order.bookingType">
                              <div [ngbPopover]="!isOrderTableOpened ? bookingTypeTitle : null"
                                   [triggers]="'hover'"
                                   popoverClass="popover-wp"
                                   placement="top"
                                   class='flight-type-wp lh-1'
                              >
                                <i *ngSwitchCase="'OW'" class="material-icons">arrow_right_alt</i>
                                <i *ngSwitchCase="'RT'" class="material-icons">swap_horiz</i>
                                <i *ngSwitchCase="'MC'" class="material-icons">linear_scale</i>
                              </div>
                            </ng-container>
                            <ng-template #bookingTypeTitle>
                              <ng-container [ngSwitch]="order.bookingType">
                                <div *ngSwitchCase="'OW'">One Way</div>
                                <div *ngSwitchCase="'RT'">Round Trip</div>
                                <div *ngSwitchCase="'MC'">Multi City</div>
                              </ng-container>
                            </ng-template>
                          </td>
                          <td>
                            <ng-container *ngIf="!flightDataStatus.isDataWrong; else emptyFlightData">
                              <div *ngFor="let route of order.flights; last as last" class="d-flex align-items-center justify-content-center gap-3 text-nowrap">
                                <ng-container *ngIf="!route.deprecated">
                                  <span class="w-36" [appTooltip]="{airportCode: route.departure.airportCode, terminalName: route.departure.terminalName}">
                                    {{ route.departure.airportCode }}
                                  </span>
                                  {{isPDFExtract ? '-' : ''}}
                                  <span class="material-icons fs-2" *ngIf="!isPDFExtract">
                                    circle
                                  </span>
                                  <span class="w-36" [appTooltip]="{airportCode: route.arrival.airportCode, terminalName: route.arrival.terminalName}">
                                      {{ route.arrival.airportCode }}
                                  </span>
                                </ng-container>
                              </div>
                            </ng-container>
                          </td>

                          <td>
                            <div
                              *ngIf="!flightDataStatus.isDataWrong; else emptyFlightData"
                              [ngbPopover]="!isOrderTableOpened ? popover : null"
                              popoverClass="popover-wp"
                              triggers="hover"
                              class="cursor-default"
                            >
                              {{ (order.flights | filterNonDeprecatedFlights)[0]?.departure.date | dateFormat | async }}
                              {{ (order.flights | filterNonDeprecatedFlights)[0]?.departure.time }}
                            </div>
                            <div *ngIf="isOrderTableOpened">
                              <ng-container [ngTemplateOutlet]="daysLeftTemplate"></ng-container>
                            </div>
                            <ng-template #popover>
                              <ng-container [ngTemplateOutlet]="daysLeftTemplate"></ng-container>
                            </ng-template>
                            <ng-template #daysLeftTemplate>
                              <span class="font-weight-bold">Days left: </span>
                              <span>{{ getDepartureRemaining(order) === 0 ? 'Today'
                                : getDepartureRemaining(order) > 0 ? getDepartureRemaining(order)
                                  : '-' }}
                              </span>
                            </ng-template>
                          </td>
                          <td width="100">
                            <app-order-status
                              [status]="order.status"
                              [statusText]="order.status | getOrderStatus : order.bookingReference?.airlineID"
                              [disruptionStatus]="order.disruptionStatus"
                              [isNotificationsExists]="viewOpts.actionRequiredButton"
                              [isPdf]="isPDFExtract"
                              [isOpened]="isOrderTableOpened"
                              (emitOpenTab)="onOpenTab()"
                            ></app-order-status>
                            <ng-container *ngIf="isOrderTableOpened && order.disruptionStatus">
                              <div class="mt-2 break-normal">{{order.disruptionStatus | addSpaceBeforeCapitalLetters}}</div>
                            </ng-container>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <div class="d-flex align-items-center gap-10 h-0" [@fadeIn]="isOrderTableOpened" [@fadeInUp]="!isOrderTableOpened">
                        <hr [ngClass]="{'w-100': isPDFExtract, 'w-50': !isPDFExtract}">
                        <ng-container *ngIf="!isPDFExtract">
                          <div class="expand-icon-wrapper" (click)="isOrderTableOpened = !isOrderTableOpened">
                            <span class="material-symbols-outlined" [@rotate]="isOrderTableOpened">
                              expand_more
                            </span>
                          </div>
                          <hr class="w-50">
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <app-order-expiration
                    *ngIf="!isPDFExtract && (order.status === ORDER_STATUS.PENDING || order.status === ORDER_STATUS.EXPIRED)"
                    [order]="order"
                    [currentDate]="currentDate">
                  </app-order-expiration>

                  <div class="row pb-5"
                       *ngIf="viewOpts.price && (pdfShowOptions.paymentInfo.isShow || !isPDFExtract)">
                    <div class="col">
                      <div class="d-flex align-items-center justify-content-between mb-4">
                        <div class="d-flex align-items-center gap-10">
                          <span class="material-symbols-outlined blue-color" *ngIf="!isPDFExtract">
                            sell
                          </span>
                          <h3 class="mb-0 h3-label">Payment</h3>
                        </div>
                        <div class="expand-icon-wrapper" (click)="isPaymentOpened = !isPaymentOpened" *ngIf="!isPDFExtract">
                          <span class="material-symbols-outlined" [@rotate]="!isPaymentOpened">
                            expand_less
                          </span>
                        </div>
                      </div>
                      <div class="row" [@collapse]="!isPaymentOpened">
                        <div class="col">
                          <div class="table-responsive">
                            <table class="table table-default price-table">
                              <thead>
                              <tr>
                                <th class="text-center">Form of Payment</th>
                                <th>Price</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td class="text-center">
                                  {{ order | paymentInfo }}
                                </td>
                                <td>
                                  <div class="d-flex flex-column align-items-center payment-info__price">
                                    <app-show-pax-breakdown-info
                                      [consumerPrice]="order.price.consumer"
                                      [passengers]="order.passengers"
                                      [services]="order.services"
                                      [seats]="order.seats"
                                      [isPDF]="isPDFExtract">
                                      <div class="total-amount">
                                        <b>{{ order.price.consumer.total | number :'1.2-2' }}</b> {{order.price.consumer.currency }}
                                      </div>
                                    </app-show-pax-breakdown-info>

                                    <app-show-taxes-info
                                      *ngIf="order.price.consumer.tax.total"
                                      [taxesInfo]="taxesInfo"
                                      [isPDF]="isPDFExtract">
                                      <div class="taxes" *ngIf="order.price.consumer.total ">
                                        Taxes: {{ order.price.consumer.tax.total | number :'1.2-2' }}
                                        {{order.price.consumer.currency }}
                                      </div>
                                    </app-show-taxes-info>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <app-corporate-info
                    [corporate]="corporate"
                    [loyaltyProgramAccount]="order.loyaltyProgramAccount"
                    [isPDFExtract]="isPDFExtract">
                  </app-corporate-info>

                  <ng-container *ngIf="!isPDFExtract" [ngTemplateOutlet]="itineraryDetailsTemplate"></ng-container>

                  <div class="row"
                       *ngIf="order.passengers">
                    <div class="col mb-0" *ngIf="!passengersDataStatus.isDataWrong; else passengersDataStatusError">

                      <!----------Prepared passengers for pdf airline-img----------->

                      <div class="passengers-pdf-wp" *ngIf="isPDFExtract && pdfShowOptions.passengers.isShow">
                        <div class="passenger-item-for-pdf" *ngFor="let p of order.passengers; let i = index">
                          <h3 class="mb-2 h3-label page-break">Passenger</h3>
                          <h2 class="red-color mb-0">{{ p.data?.title ? ('(' + p.passengerType + ') ' + p.data?.title + ' ') : ''}} {{ p.data?.name }} {{ p.data.surname}}</h2>
                          <div class="row mb-2" *ngIf="pdfShowOptions.details.isShow">
                            <div class="col-lg-12 mt-3 border-primary">
                              <h4 class="font-weight-bold">Details: </h4>
                              <div *ngIf="p.data.birthdate">
                                Birthdate: {{ p.data.birthdate | date:'dd.MM.yyyy' }}
                              </div>
                              <div *ngIf="p.data.email">Email: {{ p.data.email }}</div>
                              <div *ngIf="p.data.phone">Phone: +{{ p.data.phone }}</div>
                              <div>Reference: {{ p.travelerReference }}</div>
                              <div *ngIf="p.documents?.length" class="d-flex other-documents">
                                Documents:
                                <ul class="m-0">
                                  <li *ngFor="let document of p.documents;">
                                    {{otherDocumentTypes[document.documentType]}} {{document.documentID}}
                                  </li>
                                </ul>
                              </div>
                              <div *ngIf="p.data?.fqtvInfo?.account?.number">
                                Frequent Flyer Number: {{p.data.fqtvInfo.airlineID}} {{p.data.fqtvInfo.account.number}}
                              </div>
                            </div>

                            <div class="col-lg-12 pt-4" *ngIf="order.tickets && pdfShowOptions.ticketInformation.isShow">
                              <div class="row">
                                <div class="col">
                                  <app-tickets-info
                                    [order]="order"
                                    [passenger]="p"
                                    [isPdf]="true"
                                    [showCell]="{price: pdfShowOptions.paymentInfo.isShow}">
                                  </app-tickets-info>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-12 pt-4" *ngIf="pdfShowOptions.flightDetails.isShow">
                              <ng-container [ngTemplateOutlet]="itineraryDetailsTemplate"></ng-container>
                            </div>

                            <div class="col-lg-12 pt-4" *ngIf="pdfShowOptions.seats.isShow || pdfShowOptions.services.isShow">
                              <ng-container [ngTemplateOutlet]="ancillariesTemplate" [ngTemplateOutletContext]="{passenger: p}"></ng-container>
                            </div>
                          </div>

                          <div *ngIf="pdfShowOptions.fareRules.isShow">
                            <ng-container *ngIf="selectFareRulesByPsgType(p.passengerType)">
                              <app-fare-rules
                                [fareRules]="selectedFare"
                                [offer]="order"
                                [isPDF]="isPDFExtract">
                              </app-fare-rules>
                            </ng-container>
                          </div>

                          <ng-container *ngIf="pdfShowOptions.disclosures.isShow && order.disclosures && order.flights">
                            <app-disclosure-table
                              [disclosures]="order.disclosures"
                              [flights]="order.flights"
                              [isPdf]="true">
                            </app-disclosure-table>
                          </ng-container>
                        </div>
                      </div>

                      <ng-container *ngIf="!isPDFExtract">
                        <ng-container [ngTemplateOutlet]="passengersTemplate"></ng-container>

                        <div *ngIf="order.tickets"
                             class="row"
                             [class.pb-5]="isPassengersOpened"
                             [@collapse]="!isPassengersOpened">
                          <div class="col">
                            <app-tickets-info
                              [order]="order"
                              [passenger]="selectedPassenger">
                            </app-tickets-info>
                          </div>
                        </div>

                        <ng-container [ngTemplateOutlet]="ancillariesTemplate" [ngTemplateOutletContext]="{passenger: selectedPassenger}"></ng-container>

                        <app-osi-details
                          [otherServiceInformation]="order.otherServiceInformation"
                          [passengers]="order.passengers"
                          [isPDFExtract]="isPDFExtract">
                        </app-osi-details>

                        <ng-container *ngIf="selectFareRulesByPsgType(selectedPassenger.passengerType)">
                          <app-fare-rules
                            [fareRules]="selectedFare"
                            [offer]="order"
                            [useNewDesign]="true">
                          </app-fare-rules>
                        </ng-container>

                        <ng-container *ngIf="order.disclosures && order.flights">
                          <app-disclosure-table
                            [disclosures]="order.disclosures"
                            [flights]="order.flights"
                            [useNewDesign]="true">
                          </app-disclosure-table>
                        </ng-container>
                      </ng-container>
                    </div>
                    <ng-template #passengersDataStatusError>
                      <div class="col alert-danger mx-3 p-3 rounded">
                        {{ passengersDataStatus.errorTitle }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="row pb-4" *ngIf="isPDFExtract && pdfShowOptions.comments.isShow && order.comments">
                    <div class="col">
                      <h3>Comments</h3>
                      <div class="comments-wrapper" [innerHTML]="order.comments"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" *ngSwitchCase="orderTab.OrderHistory">
                <ng-container [ngTemplateOutlet]="airlineNotificationAlerts"></ng-container>
                <app-history
                  [order]="order"
                  [passengers]="order.passengers"
                  (emitNotificationChange)="onNotificationChange($event)"
                ></app-history>
              </div>
              <div class="tab-pane fade show active" *ngSwitchCase="orderTab.Comments">
                <app-comments
                  (emitUpdatedComment)="updateOrderComments($event)"
                  [comment]="order.comments"
                  [orderId]="order.id"
                ></app-comments>
              </div>
              <div class="tab-pane fade show active" *ngSwitchCase="orderTab.Remarks">
                <ng-container *ngIf="corporateRemarkTemplates.length && !isRemarksFilled; else agencyRemarks">
                  <ng-container *ngIf="agencyRemarkTemplates.length">
                    <h3 class="text-center mt-4 mb-0 text-capitalize"><span class="remark-name">Agency</span> Remarks</h3>
                    <ng-container [ngTemplateOutlet]="agencyRemarks"></ng-container>
                  </ng-container>
                  <h3 class="text-center mt-5 mb-0 text-capitalize"><span class="remark-name">Corporate</span> Remarks</h3>
                  <app-remarks
                    (emitUpdatedRemark)="updateCorporateRemarks($event)"
                    (emitTemplateSelected)="isRemarksTemplateSelected = $event"
                    [remark]="corporateRemarksData || order.remarks"
                    [templates]="corporateRemarkTemplates"
                    [orderId]="order.id"
                    [orderInfo]="orderInfo"
                    [additionalRemarkResult]="order.remarks.result"
                    [remarkType]="'corporate'"
                  ></app-remarks>
                </ng-container>

                <ng-template #agencyRemarks>
                  <app-remarks
                    (emitUpdatedRemark)="updateOrderRemarks($event)"
                    (emitEditBothRemarks)="editBothRemarks()"
                    (emitTemplateSelected)="isRemarksTemplateSelected = $event"
                    [remark]="order.remarks"
                    [templates]="agencyRemarkTemplates"
                    [orderId]="order.id"
                    [orderInfo]="orderInfo"
                    [additionalRemarkResult]="corporateRemarksData?.result"
                    [remarkType]="'agency'"
                  ></app-remarks>
                </ng-template>
              </div>
              <div class="tab-pane fade show active" *ngSwitchCase="orderTab.Metas">
                <app-metas
                  [metas]="order.metas"
                ></app-metas>
              </div>
              <div class="tab-pane fade show active" *ngSwitchCase="orderTab.OrderLog">
                <app-order-log [logs]="order.logs"></app-order-log>
              </div>
            </div>
          </kendo-pdf-export>
        </div>
      </div>
    </div>
    <div class="card-footer order-card-footer"
         [class.is-at-bottom]="isAtBottom">
      <ng-container *ngIf="!orderIssueProcess.isProcess && !orderConfirmProcess.isProcess; else orderProcessTemplate">
        <app-popover-wrapper
          [popoverContent]="disabledButtonPopover"
          [disablePopover]="!buttonStates.selectSeatsButton.isDisabled"
          additionalClasses="d-inline-block ml-3"
        >
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="open(seatsModal, 'lg', 'seats-modal');"
            [disabled]="buttonStates.selectSeatsButton.isDisabled">
            Select Seats
          </button>
        </app-popover-wrapper>

        <app-popover-wrapper
          [popoverContent]="disabledButtonPopover"
          [disablePopover]="!buttonStates.selectServicesButton.isDisabled"
          additionalClasses="d-inline-block ml-3"
        >
          <button
            (click)="open(serviceListModal, 'xl')"
            class="btn btn-outline-primary"
            type="button"
            [disabled]="buttonStates.selectServicesButton.isDisabled">
            Select Services
          </button>
        </app-popover-wrapper>

        <app-popover-wrapper
          [popoverContent]="disabledButtonPopover"
          [disablePopover]="!buttonStates.orderRebookButton.isDisabled"
          additionalClasses="d-inline-block ml-3"
        >
          <button
            (click)="openReshopOfferModal(reshopOfferContent, 'offers')"
            class="btn btn-outline-primary"
            type="button"
            [disabled]="buttonStates.orderRebookButton.isDisabled">
            Order Rebook
          </button>
        </app-popover-wrapper>

        <app-popover-wrapper
          [popoverContent]="disabledButtonPopover"
          [disablePopover]="!buttonStates.orderSplitButton.isDisabled"
          additionalClasses="d-inline-block ml-3"
        >
          <button
            (click)="openOrderSplitModal(orderSplitModal)"
            class="btn btn-outline-primary"
            type="button"
            [disabled]="buttonStates.orderSplitButton.isDisabled">
            Order Split
          </button>
        </app-popover-wrapper>

        <app-popover-wrapper
          *ngIf="order.status === ORDER_STATUS.PENDING"
          [popoverContent]="disabledOrderIssuePopover"
          [disablePopover]="viewOpts.airdocIssueButton && (!isRemarksIconVisible || !order.remarks.templates)"
          additionalClasses="d-inline-block float-right ml-3"
        >
          <button (click)="mandatoryRepriceBeforePayment ? onRepriceWaiting() : ''; ensurePaymentIsSet(actionAirDocIssue);"
                  [disabled]="!viewOpts.airdocIssueButton || (isRemarksIconVisible && order.remarks.templates)"
                  class="btn btn-primary">Order Issue
          </button>
        </app-popover-wrapper>

        <button
          *ngIf="viewOpts.actionRequiredButton"
          (click)="openFirstRequiredNotification(airlineNotificationModal)"
          class="btn btn-primary float-right ml-3"
          type="button">Action required
        </button>

        <button class="btn btn-primary float-right ml-3" *ngIf="viewOpts.pendingPaymentsButton"
                (click)="open(pendingPaymentsModal, 'lg', 'payment-modal')">
          ({{ order.issuingPendings.length }}) Pending payments
        </button>

        <app-popover-wrapper
          [popoverContent]="disabledButtonPopover"
          [disablePopover]="!buttonStates.orderCancelButton.isDisabled"
          additionalClasses="d-inline-block float-right ml-3"
        >
          <button
            class="btn btn-secondary"
            [disabled]="buttonStates.orderCancelButton.isDisabled"
            (click)="open(orderCancelModal, 'lg', 'cancel-modal'); orderCancelModalButtonNames.cancel.isShow ? '' : actionItinReshopRefund('refund')">
            {{orderCancelModalButtonNames.cancel.isShow ? orderCancelModalButtonNames.cancel.label : 'Cancel Options'}}
          </button>
        </app-popover-wrapper>
      </ng-container>
      <ng-template #orderProcessTemplate>
        <app-show-processing
          [processInfo]="orderIssueProcess.isProcess ? orderIssueProcess.processTitle : orderConfirmProcess.processTitle">
        </app-show-processing>
      </ng-template>
      <ng-template #disabledButtonPopover>
        Action is not available because of one of these reasons:
        <ul class="mb-0">
          <li>Your user role does not allow it</li>
          <li>The Airline does not allow it</li>
          <li>You need to resolve “Pending Payments”</li>
          <li>You need to resolve Airline disruptions</li>
          <li>The order is not active</li>
        </ul>
      </ng-template>
      <ng-template #disabledOrderIssuePopover>
        Action is not available because of one of these reasons:
        <ul class="mb-0">
          <li>Your user role does not allow it</li>
          <li>Remarks are not filled</li>
        </ul>
      </ng-template>
    </div>

    <div class="overlay-for-pdf" *ngIf="isPDFExtract">
      <div class="overlay-for-pdf__content">
        <span>PDF extracting</span>
        <app-loader></app-loader>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="showOrderRetrieveLoader">
    <div class="col mt-3">
      <div class="loader-wrapper">
        <app-loader></app-loader>
      </div>
    </div>
  </div>

  <!--  <div *ngIf="orderRetrieveError" class="row">-->
  <!--    <div class="col alert-danger m-3 p-3 rounded">-->
  <!--      {{ orderRetrieveError }}-->
  <!--    </div>-->
  <!--  </div>-->
</div>

<ng-template #serviceListModal let-c="close" let-d="dismiss">
  <app-service-list-modal
    [owner]="owner"
    [id]="id"
    [order]="order"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')"
    (emitSendOrderChangeSuccess)="onSendOrderChangeSuccess($event)"
  ></app-service-list-modal>
</ng-template>


<ng-template #seatsModal let-c="close" let-d="dismiss">
  <app-select-seats-modal
    [order]="order"
    [owner]="owner"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')"
    (emitSendOrderChangeSuccess)="onSendOrderChangeSuccess($event)">
  </app-select-seats-modal>
</ng-template>

<ng-template #orderCancelModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="card-title">{{orderCancelModalTitle}}</h3>
    <i
      *ngIf="!orderCancelProcess.isProcess"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="d('closed')"
      class="material-icons float-right close-icon">
      close
    </i>
  </div>
  <div class="modal-body">
    <error-alert-block
      [error]="orderReshopRefundError.message ? orderReshopRefundError : orderCancelError"
    ></error-alert-block>

    <div *ngIf="orderCancelResponseWarning.length && !showOrderCancelLoader">
      <div *ngIf="orderCancelResponseWarning.length" class="alert-warning p-3 mb-3 rounded">
        <ul>
          <li *ngFor="let item of orderCancelResponseWarning">{{item}}</li>
        </ul>
      </div>
    </div>

    <div class="alert-warning p-3 mb-3 rounded"
         *ngIf="orderCancelModalButtonNames.cancelAndRefund.isShow && orderCancelModalButtonNames.void.isShow && !showOrderCancelLoader">
      <ul>
        <li>
          This airline doesn't provide a mechanism to preview if the order will be voided or refunded.
          Ensure with the airline what is their specific voiding policy before taking this action.
        </li>
      </ul>
    </div>

    <div *ngIf="itinReshopRefundResponseWarning.length && !showItinReshopRefundLoader">
      <div *ngIf="itinReshopRefundResponseWarning.length" class="alert-warning p-3 mb-3 rounded">
        <ul>
          <li *ngFor="let item of itinReshopRefundResponseWarning">{{item}}</li>
        </ul>
      </div>
    </div>

    <div *ngIf="orderCancelResponseSuccess && !showOrderCancelLoader">
      <div class="alert-success p-3 mb-3 rounded">
        <div *ngIf="orderCancelType === ''">
          Order has been successfully canceled.
        </div>
        <div *ngIf="orderCancelType === 'void'">
          Order has been successfully voided.
        </div>
        <div *ngIf="orderCancelType === 'refund'">
          Order has been successfully cancelled and refunded.
        </div>
      </div>
    </div>

    <div class="loader-wrapper" *ngIf="showItinReshopRefundLoader">
      <app-loader></app-loader>
    </div>

    <ng-container *ngIf='!showItinReshopRefundLoader'>
      <ng-container *ngIf="orderCancelModalButtonNames.cancel.isShow">
        <ng-container
          [ngTemplateOutlet]="cancelConfirmation"
          [ngTemplateOutletContext]="{process: 'Cancel'}">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="orderCancelModalButtonNames.void.isShow && !orderCancelModalButtonNames.cancelAndRefund.isShow">
        <ng-container
          [ngTemplateOutlet]="cancelConfirmation"
          [ngTemplateOutletContext]="{process: 'Voiding'}">
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #cancelConfirmation let-process="process">
      <ng-container [ngSwitch]="process">
        <div class="text-center">
          <ng-container *ngSwitchCase="'Cancel'">
            <b>Are you sure you want to cancel order?</b>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <b>{{process}} available, are you sure you want to proceed ?</b>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="orderCancelModalButtonNames.cancelAndRefund.isShow && !showItinReshopRefundLoader">
      <div class="table-responsive my-4">
        <table class="table table-default highlighted-header-table mb-0">
          <tbody>
          <tr>
            <td>Penalty</td>
            <td>
              <ng-container *ngIf="penalty?.consumer?.total >= 0; else noPenaltyInfo">
                {{ penalty.consumer.total | number :'1.2-2' }} {{ penalty.consumer.currency }}
              </ng-container>
              <ng-template #noPenaltyInfo>
                N/A
              </ng-template>
            </td>
          </tr>
          <tr>
            <td width="40%">Total Refund</td>
            <td>{{ refundPrice?.consumer?.total | number :'1.2-2'  }} {{ refundPrice?.consumer?.currency }}</td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="refundPrice?.consumer?.currency !== refundPrice?.provider?.currency" class="mt-4">
          You will be refunded for an amount of {{refundPrice?.provider?.total | number :'1.2-2'}} {{refundPrice?.provider?.currency}}
        </div>
      </div>
      <div>
        <div class="refund-warning" *ngIf="isShowRefundWarning">
          <b>This order is not cancelable, the refund amount is only for some returnable taxes.</b>
        </div>
        <ng-container *ngIf="!orderCancelModalButtonNames.void.isShow"
          [ngTemplateOutlet]="cancelConfirmation"
          [ngTemplateOutletContext]="{process: 'Refund'}">
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!orderCancelProcess.isProcess; else orderCancelProcessTemplete">
      <button
        type="button"
        class="btn btn-secondary ml-auto"
        (click)="d('closed');"
      >Close
      </button>
      <span
        class="ml-3"
        [ngbPopover]="'Your User Role does not have the permission to proceed with this action.'"
        [disablePopover]="!(isCancelOrderButtonDisabled || ls.role === ROLES.BOOKER) || isGodModeEnabled"
        popoverClass="popover-wp"
        triggers="hover"
        container="body"
        *ngIf="orderCancelModalButtonNames.cancelAndRefund.isShow"
      >
        <button
          type="button"
          class="btn"
          [ngClass]="orderCancelModalButtonNames.cancelAndRefund.isShow && orderCancelModalButtonNames.void.isShow ? 'btn-tonal-primary' : 'btn-primary'"
          (click)="actionCancelOrder('refund')"
          [disabled]="(isCancelOrderButtonDisabled || ls.role === ROLES.BOOKER) && !isGodModeEnabled"
        >
          {{ orderCancelModalButtonNames.cancelAndRefund.label }}
        </button>
      </span>
      <span
        class="ml-3"
        [ngbPopover]="'Your User Role does not have the permission to proceed with this action.'"
        [disablePopover]="!(isCancelOrderButtonDisabled || (ls.role === ROLES.BOOKER && orderCancelModalButtonNames.void.isShow)) || isGodModeEnabled"
        popoverClass="popover-wp"
        triggers="hover"
        container="body"
        *ngIf="orderCancelModalButtonNames.cancel.isShow || orderCancelModalButtonNames.void.isShow"
      >
        <button
          type="button"
          class="btn btn-primary"
          (click)="openVoidingInfoModalOrCallOrderCancel(orderCancelModalButtonNames.cancel.isShow ? '' : 'void')"
          [disabled]="(isCancelOrderButtonDisabled || (ls.role === ROLES.BOOKER && orderCancelModalButtonNames.void.isShow)) && !isGodModeEnabled"
        >{{ orderCancelModalButtonNames.cancel.isShow ? orderCancelModalButtonNames.cancel.label : orderCancelModalButtonNames.void.label }}
        </button>
      </span>
    </ng-container>
    <ng-template #orderCancelProcessTemplete>
      <app-show-processing
        [processInfo]="orderCancelType === 'void' ? orderVoidProcess.processTitle : orderCancelProcess.processTitle">
      </app-show-processing>
    </ng-template>
  </div>
</ng-template>

<ng-template #orderVoidInfoModal let-c="close" let-d="dismiss">
  <div class="modal-header" ngbAutoFocus>
    <h3 class="card-title">Before you continue ...</h3>
  </div>
  <div class="modal-body">
    <h3 class="font-weight-normal text-justify lh-24">Voiding is not assured, proceeding might result in a Cancelled order instead of Voided.</h3>
    <div class="custom-control custom-checkbox my-1 mr-sm-2">
      <input type="checkbox" class="custom-control-input" id="customControlInline" (change)="ls.isVoidingInfoModalSkipped = $event.target.checked">
      <label class="custom-control-label fs-16" for="customControlInline">Do not show this message again</label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      [disabled]="orderCancelProcess.isProcess"
      (click)="d('closed')">Back
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="orderCancelProcess.isProcess"
      (click)="actionCancelOrder('void'); c('ok')">Proceed
    </button>
  </div>
</ng-template>

<ng-template #paymentFormModal let-c="close" let-d="dismiss">
  <div class="modal-card card payment" ngbAutoFocus>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Order Issue</h3>
        </div>
        <div class="col-auto">
          <i
            data-dismiss="modal"
            aria-hidden="true"
            (click)="d('closed'); loadOrder(true)"
            class="material-icons float-right close-icon"
          >close</i>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="paymentStep === 0">
        <ng-container *ngIf="showRepriceIssueLoader">
          <div class="d-flex mt-4">
            <app-loader class="mr-3 d-inline-block"></app-loader>
            {{mandatoryRepriceBeforePayment ? 'Automatically repricing order...' : 'Repricing order...'}}
          </div>
        </ng-container>
        <ng-container *ngIf="!showRepriceIssueLoader">
          <ng-container *ngIf="latestPrice">
            <div class="alert alert-warning p-3 my-4 rounded" [@fadeInOnEnter] *ngIf="viewOpts.repriceButton">
              A new price might be available with <a href="#" (click)="$event.preventDefault(); sendRepriceOrder()"><b>Reprice</b></a>. This process could make you lose the current offer.
            </div>
            <div class="alert alert-success p-3 my-4 rounded" *ngIf="latestPrice.isChanged === false">
              Price didn't change
            </div>

            <div class="offering card mt-4 disabled"
                 *ngIf="latestPrice.isChanged">
              <div class="card-header">
                <app-offer-price-header-view
                  [offer]="order">
                </app-offer-price-header-view>
              </div>
              <div class="card-body bg-white max-h-fit">
                <app-offer-price-view
                  [offer]="order">
                </app-offer-price-view>
              </div>
            </div>

            <div class="offering card"
                 *ngIf="latestPrice.isChanged && orderFromItinReshopReprice.flights">
              <div class="card-header">
                <app-offer-price-header-view
                  [offer]="orderFromItinReshopReprice"
                  [isNew]="true">
                </app-offer-price-header-view>
              </div>
              <div class="card-body bg-white max-h-fit">
                <app-offer-price-view
                  [offer]="orderFromItinReshopReprice">
                </app-offer-price-view>
              </div>
            </div>

            <ng-container [ngTemplateOutlet]="priceTemplate"
                          [ngTemplateOutletContext]="{showRepriceBtn: true}"></ng-container>

          </ng-container>

          <app-payment-form
            *ngIf="paymentService.form"
            [paymentError]="orderReshopRepriceError"
            [allowedPaymentMethods]="paymentSettings"
            [totalPrice]="latestPrice?.value"
            [showTotalPrice]='false'
            [validateTriggered]="validateTriggered"
            [paymentMethodsExists]='paymentMethodsExists'
            [cardSurchargesMap]="orderFromItinReshopReprice?.cardSurchargesMap || order?.cardSurchargesMap"
            [logs]="order?.logs"
          ></app-payment-form>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="paymentStep === 1">
        <app-payment-info
          [order]="order"
          [services]="order.services"
          [seats]="order.seats"
          [type]="'pay_order_on_hold_on_airdocissue'"
          (emitPaymentInfoError)="isPaymentInfoError = $event">
        </app-payment-info>
      </ng-container>
    </div>
    <div class="card-footer">
      <button
        *ngIf="!showRepriceIssueLoader"
        (click)="proceedPayment(c)"
        class="btn btn-primary float-right"
        [disabled]="isPaymentInfoError || !paymentMethodsExists"
      >Proceed
      </button>
      <button *ngIf="paymentStep > 0"
        (click)="paymentStep = 0"
        class="btn btn-secondary float-right mr-2"
      >Back
      </button>
      <button *ngIf="paymentStep === 0"
        (click)="cancelPayment(); d('closed');"
        class="btn btn-secondary float-right mr-2"
      >Cancel
      </button>
    </div>

  </div>
</ng-template>

<ng-template #priceTemplate let-showRepriceBtn="showRepriceBtn">
  <div
    class="payment-info__price mt-4"
    [class.payment-info__price_primary]="!latestPrice?.isChanged && (latestPrice?.value || 0) > 0"
    [class.payment-info__price_danger]="!latestPrice?.isChanged && (latestPrice?.value || 0) < 0"
    [class.payment-info__price_warning]="latestPrice?.isChanged"
  >
    <div class="changed-price total-amount">
      <span>To be paid: </span> <b>{{latestPrice?.value | number :'1.2-2'}}</b> {{latestPrice?.currency}}
      <button *ngIf="viewOpts.repriceButton && showRepriceBtn"
              class="btn btn-outline-primary p-2 ml-2"
              style="margin: inherit"
              (click)="sendRepriceOrder()">Reprice
      </button>
    </div>
  </div>
</ng-template>

<ng-template #reshopOfferContent let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Order Reissue</h3>
    <i
      data-dismiss="modal"
      aria-hidden="true"
      (click)="modal.dismiss()"
      class="material-icons float-right close-icon">
      close
    </i>
  </div>
  <div class="modal-body">
    <app-order-change
      [startedFlightsFlags]="startedFlightsFlags"
      (emitChangeSuccess)="onChangeSuccess($event)"
      (emitClose)="modal.dismiss()"
    ></app-order-change>
  </div>
</ng-template>

<ng-template #pendingPaymentsModal let-c="close">
  <app-pending-payments-modal
    [order]="order"
    [paymentMethodsExists]="paymentMethodsExists"
    (emitChangeSuccess)="onChangeSuccess($event)"
    (emitClose)="c('ok')">
  </app-pending-payments-modal>
</ng-template>


<ng-template #seatRemoveModal let-c="close" let-d="dismiss">
  <div class="modal-card card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Seat Remove Preview</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed')"></button>
        </div>
      </div>
    </div>
    <div class="card-body modal-card-body-height">
      <h3>
        Select the flight segment for which you want to remove all seats
      </h3>
      <div class="table-responsive">
        <table class="table table-default">
          <thead>
          <tr>
            <th>Flight Segment</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let od of order.flights">
            <ng-container *ngFor="let fs of od.segments | filterSegmentsBySelectedSeats: order.seats">
              <tr>
                <td>
                  {{fs.originDestination.departure.airportCode}}
                  → {{fs.originDestination.arrival.airportCode}}
                </td>
                <td>
                  <ng-container *ngIf="selectedSegmentsMapCopy[fs.segmentID]">
                    <span
                      class="btn btn-sm btn-tonal-primary mw-74"
                      (click)="toggleSegmentSelection(fs.segmentID)">
                      Selected
                    </span>
                  </ng-container>
                  <ng-container *ngIf="!selectedSegmentsMapCopy[fs.segmentID]">
                    <span
                      class="btn btn-sm btn-outline-primary mw-74"
                      (click)="toggleSegmentSelection(fs.segmentID)">
                      Select
                    </span>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="alert alert-warning" *ngIf="seatsToRemove.length">
        This action will remove all seats from selected segment
      </div>
      <div class="table-responsive">
        <table class="table table-default mb-0">
          <thead>
          <tr>
            <th>Passenger</th>
            <th>Flight Segment</th>
            <th>Seat</th>
            <th>Price</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="seatsToRemove.length">
            <tr *ngFor="let seat of seatsToRemove | keyvalue">
              <td>{{order.passengers | getPassengerNameByRefPipe:seat.value.refs}}</td>
              <td class="whitespace-pre-line">{{order.flights | getSegmentRouteByIDPipe:seat.value.segment}}
              </td>
              <td>{{seat.value.location.row | padStart:2:'0' }}{{seat.value.location.column}}</td>
              <td>{{seat.value.price.consumer.total ? seat.value.price.consumer.total + ' ' + seat.value.price.consumer.currency : 'Free'}}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <ng-container *ngIf="!seatsToRemove.length">
          <div class="alert alert-warning">
            Segment not selected
          </div>
        </ng-container>
      </div>
    </div>

    <div class="card-footer">
      <div class="btn-wrapper">
        <button (click)="handleRemoveSeats(c); "
              class="btn btn-primary float-right ml-3"
              [disabled]="!seatsToRemove.length">Remove Seats</button>
        <button (click)="d('closed'); cancelRemovingSeats()" class="btn btn-secondary float-right">Cancel</button>
      </div>
    </div>

  </div>
</ng-template>

<div class="on-error-wp" *ngIf="orderRetrieveError.message">
  <div class="err-btn-wp">
    <button class="btn btn-secondary btn-back" (click)="onBackNavigation()">Go back</button>
    <button class="btn btn-primary btn-again" (click)="onTryReload()">Try again</button>
  </div>
</div>

<ng-template #itineraryDetailsTemplate>
  <div class="row pb-5">
    <div class="col" *ngIf="!flightDataStatus.isDataWrong; else flightDataStatusError">
      <app-itinerary-details
        [flights]="order.flights"
        [disclosures]="order.disclosures"
        [fareNames]="fareNames"
        [passengers]="order.passengers"
        [isPDFExtract]="isPDFExtract">
      </app-itinerary-details>
    </div>
    <ng-template #flightDataStatusError>
      <div class="col alert-danger mx-3 p-3 rounded">
        {{ flightDataStatus.errorTitle }}
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #passengersTemplate>
  <div class="row pb-5">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex align-items-center gap-10">
          <span class="material-symbols-outlined blue-color">
            {{order.passengers?.length > 1 ? 'group' : 'person'}}
          </span>
          <h3 class="mb-0 h3-label">{{order.passengers?.length > 1 ? 'Passengers' : 'Passenger'}}</h3>
        </div>
        <div class="expand-icon-wrapper" (click)="isPassengersOpened = !isPassengersOpened">
          <span class="material-symbols-outlined" [@rotate]="!isPassengersOpened">
            expand_less
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-12 overflow-unset" [@collapse]="!isPassengersOpened">
      <div ngbDropdown class="passengers-dropdown" [class.readonly]="order.passengers?.length === 1 || passengersDropdownDisabled">
        <button class="btn btn-outline-primary d-inline-flex" id="passengers-dropdown" ngbDropdownToggle>
          <span>
            {{'(' + selectedPassenger.passengerType + ')'}} {{selectedPassenger.data?.title || ''}} {{ selectedPassenger.data?.name }} {{ selectedPassenger.data.surname}}
          </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="passengers-dropdown">
          <button ngbDropdownItem *ngFor="let passenger of order.passengers" (click)="selectPassenger(passenger)">
            {{'(' + passenger.passengerType + ')'}} {{passenger.data?.title || ''}} {{ passenger.data?.name }} {{ passenger.data.surname}}
          </button>
        </div>
      </div>

      <div class="mt-4 sentry-mask" [formGroup]="passengerForm">
        <ng-container *ngFor="let passenger of availablePassengersToUpdate; let i = index; first as first">
          <ng-container *ngIf="passenger === selectedPassenger && 'passenger_' + i as psgKey">
            <div class="card passenger-card">
              <div class="card-header" (click)="isPassengersDataOpened.details = !isPassengersDataOpened.details">
                <div class="text-uppercase">Details</div>
                <div class="d-flex justify-content-end">
                  <i class="material-symbols-outlined fs-20"
                     [@rotate]="isPassengersDataOpened.details">
                    expand_more
                  </i>
                </div>
              </div>
              <div class="card-body"
                   [class.closed]="!isPassengersDataOpened.details"
                   [@collapse]="!isPassengersDataOpened.details"
                   [formGroupName]="psgKey">
                <ng-container formGroupName="data">
                  <div class="form-row">
                    <div class="form-group-sm col-md-3">
                      <label for="givenName">Given name / First name</label>
                      <input type="text"
                             class="form-control"
                             id="givenName"
                             placeholder="Alex"
                             name="givenName"
                             formControlName="name"
                             [validate]="passengerForm.get(psgKey).get('data.name').dirty"
                             [maxCharsAllowed]="allowedPassengerUpdates.pax_info_correction?.maxCharsAllowed">
                    </div>
                    <div class="form-group-sm col-md-3">
                      <label for="surname">Surname / Last name</label>
                      <input type="text"
                             class="form-control"
                             id="surname"
                             placeholder="Crow"
                             name="surname"
                             formControlName="surname"
                             [validate]="passengerForm.get(psgKey).get('data.surname').dirty"
                             [maxCharsAllowed]="allowedPassengerUpdates.pax_info_correction?.maxCharsAllowed">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group-sm col-md-2">
                      <label for="p-title">Title</label>
                      <ng-select2
                        [validate]="passengerForm.get(psgKey).get('data.title').dirty"
                        [maxCharsAllowed]="allowedPassengerUpdates.pax_info_correction?.maxCharsAllowed"
                        class="ng-select2-form-control"
                        formControlName="title"
                        [placeholder]="'Select title ...'"
                        (click)="helpers.setFocusToSelect2SearchField()"
                        id="p-title"
                      >
                        <option *ngFor="let availableTitle of titles" [value]="availableTitle">{{ availableTitle | uppercase }}
                      </ng-select2>
                    </div>
                    <div class="form-group-sm col-md-2">
                      <label for="gender">Gender</label>
                      <ng-select2
                        [validate]="passengerForm.get(psgKey).get('data.gender').dirty"
                        class="ng-select2-form-control"
                        formControlName="gender"
                        [placeholder]="'Select gender ...'"
                        (click)="helpers.setFocusToSelect2SearchField()"
                        id="gender"
                      >
                        <option *ngFor="let gen of genders" [value]="gen">{{ gen }}
                      </ng-select2>
                    </div>
                  </div>
                  <ng-container *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.pax_info_correction && passengerForm.get(psgKey).get('data').dirty">
                    <ng-container [ngTemplateOutlet]="cardFooter" [ngTemplateOutletContext]="{action: 'pax_info_correction'}"></ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="card passenger-card mt-3"
                 *ngIf="passenger.data.birthdate || allowedUpdatesPerPassengerType[passenger.passengerType]?.birthdate_correction">
              <div class="card-header" (click)="isPassengersDataOpened.birthdate = !isPassengersDataOpened.birthdate">
                <div class="text-uppercase">Birthdate</div>
                <div class="d-flex justify-content-end">
                  <i class="material-symbols-outlined fs-20"
                     [@rotate]="isPassengersDataOpened.birthdate">
                    expand_more
                  </i>
                </div>
              </div>
              <div class="card-body"
                   [class.closed]="!isPassengersDataOpened.birthdate"
                   [formGroupName]="psgKey"
                   [@collapse]="!isPassengersDataOpened.birthdate">
                <ng-container formGroupName="data">
                  <div class="form-row">
                    <div class="form-group-sm col-md-3">
                      <label for="birthdate">Date of birth</label>
                      <div class="datepicker-container" #datePicker>
                        <input
                          appDateInput
                          autocomplete="false"
                          [placement]="'right'"
                          ngbDatepicker
                          #p="ngbDatepicker"
                          (click)="p.close();"
                          (ngModelChange)="onBirthdateChanged($event, psgKey, passenger.passengerType)"
                          class="form-control"
                          id="birthdate"
                          placeholder="DD-MM-YY"
                          formControlName="birthdate"
                          container="body"
                          [validate]="passengerForm.get(psgKey + '.data.birthdate').dirty"
                          [minDate]="minMaxDateOfBirth[(passenger.passengerType | travelerTypePipe).toUpperCase()]?.minDate"
                          [maxDate]="minMaxDateOfBirth[(passenger.passengerType | travelerTypePipe).toUpperCase()]?.maxDate"
                          [minMaxDateOfBirth]="minMaxDateOfBirth[(passenger.passengerType | travelerTypePipe).toUpperCase()]"
                        />
                        <button (click)="selectBirthdate(datePicker); p.toggle();">
                          <i class="material-icons" style='color: white'>
                            event
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.birthdate_correction && passengerForm.get(psgKey + '.data.birthdate').dirty">
                  <ng-container [ngTemplateOutlet]="cardFooter" [ngTemplateOutletContext]="{action: 'birthdate_correction'}"></ng-container>
                </ng-container>
              </div>
            </div>
            <div class="card passenger-card mt-3"
                 *ngIf="passenger.data.phone || passenger.data.email || allowedUpdatesPerPassengerType[passenger.passengerType]?.pax_info_correction">
              <div class="card-header" (click)="isPassengersDataOpened.contact = !isPassengersDataOpened.contact">
                <div class="text-uppercase">Contact</div>
                <div class="d-flex justify-content-end">
                  <i class="material-symbols-outlined fs-20"
                     [@rotate]="isPassengersDataOpened.contact">
                    expand_more
                  </i>
                </div>
              </div>
              <div class="card-body overflow-unset"
                   [class.closed]="!isPassengersDataOpened.contact"
                   [@collapse]="!isPassengersDataOpened.contact"
                   [formGroupName]="psgKey">
                <ng-container formGroupName="data">
                  <div class="form-row">
                    <div class="form-group-sm col-md-3">
                      <label for="email">Email</label>
                      <input type="text"
                             id="email"
                             formControlName="email"
                             [validate]="passengerForm.get(psgKey).get('data.email').dirty"
                             autocomplete="false"
                             class="form-control"
                             placeholder="web@example.com"/>
                    </div>
                    <div class="form-group-sm col-md-3" formGroupName="phone" *ngIf="passenger.data.phone || allowedPassengerUpdates.pax_info_correction?.fieldsToUpdate?.includes('Phone')">
                      <label for="phone">Phone</label>
                      <input [validate]="passengerForm.get(psgKey + '.data.phone.number').dirty"
                             (countryChange)="onCountryChange(psgKey, $event)"
                             (intlTelInputObject)="telInputObject(psgKey, $event)"
                             [ng2TelInputOptions]="helpers.defaultTelInputOptions"
                             class="form-control"
                             id="phone"
                             formControlName="number"
                             ng2TelInput>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.pax_info_correction && passengerForm.get(psgKey).get('data').dirty">
                  <ng-container [ngTemplateOutlet]="cardFooter" [ngTemplateOutletContext]="{action: 'pax_info_correction'}"></ng-container>
                </ng-container>
              </div>
            </div>
            <div class="card passenger-card mt-3"
                 *ngIf="first && (passenger.data.address?.cityName || passenger.data.address?.countryCode
                                       || passenger.data.addresses?.length || allowedUpdatesPerPassengerType[passenger.passengerType]?.address_correction)">
              <div class="card-header" (click)="isPassengersDataOpened.address = !isPassengersDataOpened.address">
                <div class="text-uppercase">Address</div>
                <div class="d-flex justify-content-end">
                  <i class="material-symbols-outlined fs-20"
                     [@rotate]="isPassengersDataOpened.address">
                    expand_more
                  </i>
                </div>
              </div>
              <div class="card-body"
                   [class.closed]="!isPassengersDataOpened.address"
                   [@collapse]="!isPassengersDataOpened.address"
                   [formGroupName]="psgKey">
                <ng-container formGroupName="data">
                  <ng-container *ngIf="addresses.home" formGroupName="address">
                    <div class="fs-16 fw-500 mb-2">
                      Home address
                    </div>
                    <div class="form-row">
                      <div class="form-group-sm col-md-3">
                        <label>Country</label>
                        <ng-select2
                          formControlName="countryCode"
                          class="ng-select2-form-control"
                          [placeholder]="'Select country ...'"
                          (click)="helpers.setFocusToSelect2SearchField()"
                        >
                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                          </option>
                        </ng-select2>
                      </div>
                      <div class="form-group-sm col-md-3">
                        <label>City</label>
                        <input
                          type="text"
                          formControlName="cityName"
                          placeholder="Madrid"
                          autocomplete="false"
                          class="form-control">
                      </div>
                      <div class="form-group-sm col-md-2">
                        <label>Postal code</label>
                        <input
                          type="text"
                          formControlName="postalCode"
                          placeholder="28001"
                          autocomplete="false"
                          class="form-control ">
                      </div>
                      <div class="form-group-sm col-md-4">
                        <label>Street</label>
                        <div class="form-row">
                          <div class="col-6">
                            <input
                              type="text"
                              formControlName="street"
                              placeholder="Gran Via"
                              autocomplete="false"
                              class="form-control">
                          </div>
                          <div class="col d-flex align-items-center"
                               *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.address_correction">
                            <i class="material-symbols-outlined remove-icon"
                               title="Remove"
                               (click)="removeAddress('home', psgKey + '.data.address')">
                              delete
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="addresses.destination" formGroupName="addresses">
                    <div class="fs-16 fw-500 mb-2">
                      Destination address
                    </div>
                    <ng-container *ngFor="let control of passengerForm.get(psgKey + '.data.addresses')['controls']; let idx = index" [formGroupName]="idx">
                      <div class="form-row">
                        <div class="form-group-sm col-md-3">
                          <label>Country</label>
                          <ng-select2
                            formControlName="countryCode"
                            class="ng-select2-form-control"
                            [placeholder]="'Select country ...'"
                            (click)="helpers.setFocusToSelect2SearchField()"
                          >
                            <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                            </option>
                          </ng-select2>
                        </div>
                        <div class="form-group-sm col-md-3">
                          <label>City</label>
                          <input
                            type="text"
                            formControlName="cityName"
                            placeholder="Madrid"
                            autocomplete="false"
                            class="form-control">
                        </div>
                        <div class="form-group-sm col-md-2">
                          <label>Postal code</label>
                          <input
                            type="text"
                            formControlName="postalCode"
                            placeholder="28001"
                            autocomplete="false"
                            class="form-control">
                        </div>
                        <div class="form-group-sm col-md-4">
                          <label>Street</label>
                          <div class="form-row">
                            <div class="col-6">
                              <input
                                type="text"
                                formControlName="street"
                                placeholder="Gran Via"
                                autocomplete="false"
                                class="form-control">
                            </div>
                            <div class="col d-flex align-items-center"
                                 *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.address_correction">
                              <i class="material-symbols-outlined remove-icon"
                                 title="Remove"
                                 (click)="removeAddress('destination', psgKey + '.data.addresses', idx)">
                                delete
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <div class="form-row mt-3" *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.address_correction">
                    <div class="form-group-sm col-md-3">
                      <ng-select2
                        [placeholder]="'Add address'"
                        (click)="helpers.setFocusToSelect2SearchField();"
                        formControlName="addressType"
                        class="ng-select2-control select2-control"
                        (valueChanged)="addAddress($event, passenger, psgKey)"
                      >
                        <option value="">Add address</option>
                        <option value="home" *ngIf="!addresses.home">Home</option>
                        <option value="destination">Destination</option>
                      </ng-select2>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.address_correction &&
                                      (passengerForm.get(psgKey).get('data.address').dirty || passengerForm.get(psgKey).get('data.addresses').dirty)">
                  <ng-container [ngTemplateOutlet]="cardFooter" [ngTemplateOutletContext]="{action: 'address_correction'}"></ng-container>
                </ng-container>
              </div>
            </div>
            <div class="card passenger-card mt-3"
                 *ngIf="passenger.documents?.length || allowedUpdatesPerPassengerType[passenger.passengerType]?.document_correction">
              <div class="card-header" (click)="isPassengersDataOpened.documents = !isPassengersDataOpened.documents">
                <div class="text-uppercase">Documents</div>
                <div class="d-flex justify-content-end">
                  <i class="material-symbols-outlined fs-20"
                     [@rotate]="isPassengersDataOpened.documents">
                    expand_more
                  </i>
                </div>
              </div>
              <div class="card-body overflow-unset"
                   [class.closed]="!isPassengersDataOpened.documents"
                   [@collapse]="!isPassengersDataOpened.documents"
                   [formGroupName]="psgKey">
                <ng-container formGroupName="documents">
                  <div *ngFor="let control of passengerForm.get(psgKey + '.documents')['controls']; let idx = index" [formGroupName]="idx">
                    <div class="form-row">
                      <div class="col-md-6 d-flex align-items-center gap-20 mb-3">
                        <div class="fs-16 fw-500"
                             ngbTooltip="Some countries need Fiscal ID to be added to sell in some countries like Ecuador or Perú"
                             container="body">
                          {{otherDocumentTypes[control.get('documentType').value]}}
                        </div>
                        <i class="material-symbols-outlined remove-icon fs-18"
                           *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.document_correction"
                           title="Remove"
                           (click)="removeDocument(psgKey + '.documents', idx)">
                          delete
                        </i>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group-sm col-md-3" *ngIf="control.get('documentID') as documentID">
                        <label container="body" ngbTooltip="Iberia's Fiscal ID need this format: RUC/12345789/COMPANY">
                          <span for="documentID" class="text-primary"><b>*</b></span> Document ID
                        </label>
                        <input [validate]="documentID.dirty"
                               type="text"
                               class="form-control"
                               formControlName="documentID"
                               id="documentID"
                               autocomplete="false"
                               placeholder="XXXXXXX"/>
                      </div>
                      <div class="form-group-sm col-md-3" *ngIf="control.get('expirationDate') as expirationDate">
                        <label for="expirationDate">Expiration date</label>
                        <div class="datepicker-container">
                          <input
                            appDateInput
                            [validate]="expirationDate.dirty"
                            autocomplete="false"
                            [placement]="'right'"
                            ngbDatepicker
                            #t="ngbDatepicker"
                            (click)="t.toggle()"
                            (ngModelChange)="onExpirationDateChanged($event, control)"
                            class="form-control"
                            placeholder="DD-MM-YY"
                            container="body"
                            formControlName="expirationDate"
                            id="expirationDate"
                            [minDate]="minMaxDocumentExpirationDate.minDate"
                          />
                          <button (click)="t.toggle()">
                            <i class="material-icons" style='color: white'>
                              event
                            </i>
                          </button>
                        </div>
                      </div>
                      <div class="form-group-sm col-md-3" *ngIf="control.get('fiscalName') as fiscalName">
                        <label for="fiscalName">Fiscal Name</label>
                        <input
                          [validate]="fiscalName.dirty"
                          type="text"
                          formControlName="fiscalName"
                          id="fiscalName"
                          placeholder="XXXXXXX"
                          autocomplete="false"
                          class="form-control">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group-sm col-md-3" *ngIf="control.get('issuingCountryCode') as issuingCountryCode">
                        <label for="issuingCountryCode">Issuing Country Code</label>
                        <ng-select2
                          [validate]="issuingCountryCode.dirty"
                          class="ng-select2-form-control"
                          [placeholder]="'Select Issuing Country Code...'"
                          formControlName="issuingCountryCode"
                          id="issuingCountryCode"
                          (click)="helpers.setFocusToSelect2SearchField()"
                        >
                          <option value=""></option>
                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                        </ng-select2>
                      </div>
                      <div class="form-group-sm col-md-3" *ngIf="control.get('citizenshipCountryCode') as citizenshipCountryCode">
                        <label for="citizenshipCountryCode">Citizenship Country Code</label>
                        <ng-select2
                          [validate]="citizenshipCountryCode.dirty"
                          class="ng-select2-form-control"
                          [placeholder]="'Select Citizenship Country Code...'"
                          formControlName="citizenshipCountryCode"
                          id="citizenshipCountryCode"
                          (click)="helpers.setFocusToSelect2SearchField()"
                        >
                          <option value=""></option>
                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                        </ng-select2>
                      </div>
                      <div class="form-group-sm col-md-3" *ngIf="control.get('residenceCountryCode') as residenceCountryCode">
                        <label for="residenceCountryCode">Residence Country Code</label>
                        <ng-select2
                          [validate]="residenceCountryCode.dirty"
                          class="ng-select2-form-control"
                          [placeholder]="'Select Residence Country Code...'"
                          formControlName="residenceCountryCode"
                          id="residenceCountryCode"
                          (click)="helpers.setFocusToSelect2SearchField()"
                        >
                          <option value=""></option>
                          <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                        </ng-select2>
                      </div>
                    </div>
                  </div>
                  <div class="documents-dropdown mb-4"
                       [class.mt-2]="passengerForm.get(psgKey + '.documents')['controls']?.length"
                       ngbDropdown
                       *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.document_correction">
                    <button class="d-flex btn btn-outline-primary" ngbDropdownToggle>
                      Add document
                    </button>
                    <div ngbDropdownMenu>
                      <div ngbDropdownItem *ngFor="let type of (otherDocumentTypes | pairs)" (click)="addDocument(psgKey, type[0], passenger);">{{ type[1] }}</div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.document_correction && passengerForm.get(psgKey).get('documents').dirty">
                  <ng-container [ngTemplateOutlet]="cardFooter" [ngTemplateOutletContext]="{action: 'document_correction'}"></ng-container>
                </ng-container>
              </div>
            </div>
            <div class="card passenger-card mt-3"
                 *ngIf="passenger.data?.fqtvInfo?.account?.number || order.loyaltyProgramAccount || allowedUpdatesPerPassengerType[passenger.passengerType]?.fqtv_ob_adding_correction">
              <div class="card-header" (click)="first && (isPassengersDataOpened.ffn = !isPassengersDataOpened.ffn)">
                <div class="text-uppercase">FQTV & OB number</div>
                <div class="d-flex justify-content-end">
                  <i class="material-symbols-outlined fs-20"
                     [@rotate]="isPassengersDataOpened.ffn">
                    expand_more
                  </i>
                </div>
              </div>
              <div class="card-body"
                   [class.closed]="!isPassengersDataOpened.ffn"
                   [formGroupName]="psgKey"
                   [@collapse]="!isPassengersDataOpened.ffn">
                <ng-container formGroupName="data" *ngIf="order.allowedExtraFields?.frequentFlyerNumber">
                  <div class="form-row" formGroupName="fqtvInfo">
                    <div class="form-group-sm col-md-3">
                      <label>Frequent flyer number details</label>
                      <ng-select2
                        [validate]="passengerForm.get(psgKey + '.data.fqtvInfo.account.number').dirty ||
                                    passengerForm.get(psgKey + '.data.fqtvInfo.airlineID').dirty"
                        class="ng-select2-form-control"
                        formControlName="airlineID"
                        [placeholder]="'Select Airline Designator ...'"
                        (click)="helpers.setFocusToSelect2SearchField()">
                        <option *ngFor="let c of (dictionaryAirlineDesignators | pairs)" [value]="c[0]">{{ c[1] }}
                      </ng-select2>
                    </div>
                    <div class="form-group-sm col-md-3" formGroupName="account">
                      <label>&nbsp;</label>
                      <input
                        [validate]="passengerForm.get(psgKey + '.data.fqtvInfo.account.number').dirty ||
                                    passengerForm.get(psgKey + '.data.fqtvInfo.airlineID').dirty"
                        type="text"
                        formControlName="number"
                        placeholder="Frequent Flyer Number"
                        autocomplete="false"
                        class="form-control">
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="allowedPassengerUpdates.fqtv_ob_adding_correction?.fieldsToUpdate?.includes('loyaltyProgramAccount') || order.loyaltyProgramAccount">
                  <ng-container [ngTemplateOutlet]="loyaltyProgramAccountTmpl"></ng-container>
                </ng-container>
                <ng-container *ngIf="allowedUpdatesPerPassengerType[passenger.passengerType]?.fqtv_ob_adding_correction &&
                                     passengerForm.get(psgKey + '.data.fqtvInfo').dirty || passengerForm.get('loyaltyProgramAccount').dirty">
                  <ng-container [ngTemplateOutlet]="cardFooter" [ngTemplateOutletContext]="{action: 'fqtv_ob_adding_correction'}"></ng-container>
                </ng-container>
              </div>
              <ng-template #loyaltyProgramAccountTmpl>
                <div class="form-row">
                  <div class="form-group col-md-3" [formGroup]="passengerForm">
                    <label>Corporate Loyalty Program</label>
                    <input
                      [validate]="passengerForm.get('loyaltyProgramAccount').dirty"
                      type="text"
                      formControlName="loyaltyProgramAccount"
                      autocomplete="false"
                      placeholder="OBXXXXX..."
                      class="form-control">
                  </div>
                </div>
              </ng-template>
            </div>

            <ng-template #cardFooter let-action="action">
              <div class="mb-4" *ngIf="currentOrderUpdateAction === action && orderUpdateProcess.isProcess">
                <app-show-processing
                  [processInfo]="orderUpdateProcess.processTitle">
                </app-show-processing>
              </div>
              <div class="form-row" *ngIf="!orderUpdateProcess.isProcess">
                <div class="col-lg-12 text-center">
                  <button type="button"
                          class="btn btn-outline-primary"
                          (click)="cancelPassengerEditing()">Cancel</button>
                  <button type="submit"
                          class="btn btn-tonal-primary ml-3"
                          [disabled]="passengerForm.invalid"
                          (click)="updatePassengerInfo(action)">Save</button>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ancillariesTemplate let-passenger="passenger">
  <ng-container *ngFor="let od of order.flights; let odIdx = index; first as firstOD">
    <ng-container *ngFor="let fs of od.segments; let fsIdx = index; first as firstFS">
      <div *ngIf="order | segmentHasAncillaries: passenger : fs"
           class="row"
           [class.pb-5]="isPassengersOpened"
           [@collapse]="!isPassengersOpened">
        <div class="col">
          <h4 class="ml-5" *ngIf="firstOD && firstFS">Ancillaries</h4>
          <div class="d-flex gap-10 flight-route mb-2 ml-5">
            <div class="h-24 w-24">
              <app-airline-img
                [airlineID]="fs.marketingCarrier.airlineID"
                [aircraftCode]="fs.equipment.aircraftCode"
                [title]="(fs.marketingCarrier.airlineID | getAirline) + ' - ' + fs.marketingCarrier.name ">
              </app-airline-img>
            </div>
            <div>
              <span [appTooltip]="{airportCode: fs.originDestination.departure.airportCode, terminalName:fs.originDestination.departure.terminalName}">
                {{fs.originDestination.departure.airportCode}}
              </span>
              {{isPDFExtract ? '-' : '→'}}
              <span [appTooltip]="{airportCode: fs.originDestination.arrival.airportCode, terminalName:fs.originDestination.arrival.terminalName}">
                {{fs.originDestination.arrival.airportCode}}
              </span>
            </div>
            <span>
              ({{ fs.marketingCarrier?.airlineID + fs.marketingCarrier?.flightNumber }})
            </span>
          </div>
          <div>
            <ng-container
              *ngTemplateOutlet="orderChangerSeatSegmentID[fs.segmentID] || orderChangerServiceSegmentID.segmentId === fs.segmentID
                                                            ? segmentAncillariesLoaderTemplate
                                                            : segmentAncillariesContent">
            </ng-container>

            <ng-template #segmentAncillariesLoaderTemplate>
              <div class="loader-wrapper" *ngIf="orderChangerSeatSegmentID.showLoader || orderChangerServiceSegmentID.showLoader">
                <app-loader></app-loader>
              </div>
            </ng-template>

            <ng-template #segmentAncillariesContent>
              <ng-container>
                <table class="mb-0 table table-responsive table-default">
                  <thead>
                  <tr (click)="toggleAncillariesBySegment(odIdx, fsIdx)"
                      class="cursor-pointer">
                    <th style="width: 20px;">#</th>
                    <th class="text-left" style="width: 280px;">Name</th>
                    <th class="text-left">Description</th>
                    <th style="width: 170px;" *ngIf="pdfShowOptions.paymentInfo.isShow">Price</th>
                    <th style="width: 60px" *ngIf="!isPDFExtract">
                      <div class="d-flex justify-content-end">
                        <i class="material-symbols-outlined fs-20" [@rotate]="!(isAncillariesTableBySegmentHidden[odIdx] && isAncillariesTableBySegmentHidden[odIdx][fsIdx])">
                          expand_more
                        </i>
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody *ngIf="pdfShowOptions.services.isShow || !isPDFExtract"
                         [ngClass]="!(isAncillariesTableBySegmentHidden[odIdx] && isAncillariesTableBySegmentHidden[odIdx][fsIdx]) ? '' : 'd-none'">
                  <tr *ngFor="let srv of (order.services | servicePerPsgAndSegment:passenger.travelerReference:fs.segmentID:odIdx:fsIdx)"
                      [class.expanded]="srv.isExpanded">
                    <td>{{ srv.minimumQuantity }} </td>
                    <td class="text-left">
                      <div>
                        {{ srv?.name || ''}}
                      </div>
                    </td>
                    <td class="text-left">
                      <div class="d-flex align-items-center justify-content-between" *ngIf="srv.descriptions?.length">
                        <div>
                          <ng-container *ngFor="let description of srv.descriptions; let i = index">
                            <div *ngIf="(srv.isExpanded || i == 0) && description !== srv.name">
                              {{description}} {{srv.descriptions.length > 1 && !srv.isExpanded ? '...' : ''}}
                            </div>
                          </ng-container>
                        </div>
                        <button class="btn btn-secondary align-self-baseline py-1"
                                *ngIf="srv.descriptions?.length > 1"
                                (click)="srv.isExpanded = !srv.isExpanded">
                          <i class='fa fs-10' [ngClass]="!srv.isExpanded ? 'fa-plus' : 'fa-minus'"></i>
                        </button>
                      </div>
                      <div *ngIf="!srv.descriptions?.length">
                        -
                      </div>
                    </td>
                    <td *ngIf="pdfShowOptions.paymentInfo.isShow">
                      {{ srv.price.consumer?.total ? srv.price.consumer.total + ' ' + srv.price.consumer.currency : srv.priceHidden ? '-' :  'Free' }}
                    </td>
                    <td *ngIf="!isPDFExtract">
                      <ng-container *ngIf="isPendingOrder() || isTicketedOrder()">
                        <ng-container *ngIf="srv.priceHidden">
                          -
                        </ng-container>
                        <ng-container *ngIf="!srv.priceHidden">
                          <div class="d-flex justify-content-end">
                            <i *ngIf="srv.removable;"
                               title="Remove"
                               class="material-symbols-outlined remove-icon"
                               (click)="handleRemoveService(srv, fs.segmentID)">
                              delete
                            </i>
                          </div>
                        </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                  </tbody>
                  <tbody *ngIf="(order.seats | seatsPerPsgAndSegment: passenger.travelerReference: fs.segmentID)?.length &&
                                              (pdfShowOptions.seats.isShow || !isPDFExtract)"
                         [ngClass]="!(isAncillariesTableBySegmentHidden[odIdx] && isAncillariesTableBySegmentHidden[odIdx][fsIdx]) ? '' : 'd-none'">
                  <tr class="h-20"></tr>
                  <tr *ngFor="let seat of order.seats | seatsPerPsgAndSegment: passenger.travelerReference: fs.segmentID">
                    <td>
                      <span class="d-flex material-symbols-outlined" *ngIf="!isPDFExtract">
                        airline_seat_recline_extra
                      </span>
                    </td>
                    <td class="text-left">Seat</td>
                    <td class="text-left">
                      {{seat.location.row.toString().padStart(2, '0')}}{{seat.location.column}}
                    </td>
                    <td *ngIf="pdfShowOptions.paymentInfo.isShow">
                      {{seat.price.consumer.total ? seat.price.consumer.total + ' ' + seat.price.consumer.currency : 'Free'}}
                    </td>
                    <td *ngIf="!isPDFExtract">
                      <div class="d-flex justify-content-end">
                        <i *ngIf="seat.removable;"
                           title="Remove"
                           class="material-symbols-outlined remove-icon"
                           (click)="openPreviewSeatRemovalModal(seat, seatRemoveModal)">
                          delete
                        </i>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-template>
          </div>

          <div *ngIf="orderChangeWarning.length" class="row">
            <div class="col m-3 p-3 alert-warning">
              <ul>
                <li *ngFor="let item of orderChangeWarning">{{item}}</li>
              </ul>
            </div>
          </div>

          <div class="row mt-3" *ngIf="(passenger?.specialServices | ssrPerPsgAndSegment: fs.segmentID)?.length">
            <div class="col">
              <div class="row">
                <div class="col-lg-12">
                  <h6>Special Services:</h6>
                  <ng-container>
                    <table class="table table-responsive">
                      <thead>
                      <tr>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Segment</th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container
                        *ngFor="let ssr of passenger.specialServices | ssrPerPsgAndSegment: fs.segmentID">
                        <tr>
                          <td>{{ ssr.code }}</td>
                          <td>{{ ssr.desc }}</td>
                          <td
                            class="td-OD">{{ fs.originDestination.departure.airportCode }}
                            → {{fs.originDestination.arrival.airportCode}}</td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template #orderSplitModal let-c="close" let-d="dismiss">
  <app-order-split-modal
    [owner] = "owner"
    [order] ="order"
    (emitOrderSplitSuccess) = "d('closed') ; loadOrder(true);"
    (emitClose) ="c('ok')"
    (emitDismiss) ="d('closed')"
  >
  </app-order-split-modal>
</ng-template>

<ng-template #emptyFlightData>
  <div>-</div>
</ng-template>

<ng-template #pendingPaymentsWarning>
  <div class="alert alert-warning cursor-pointer fs-15 fw-500 my-3"
       *ngIf="isShowPendingPaymentsWarning"
       (click)="open(pendingPaymentsModal, 'lg', 'payment-modal')">
    <app-date-with-popover
      class="fs-14"
      [date]="currentDate | date: 'yyyy-MM-dd HH:mm'">
    </app-date-with-popover>
    <span> | There was an issue processing payment. Please, double-check the status of the Order and Tickets - </span>
    <span class="dark-red-color">Pending Payments</span>
  </div>
</ng-template>

<ng-template #airlineNotificationAlerts>
  <ng-container *ngIf="order.airlineNotifications?.length">
    <ng-container *ngFor="let airlineNotification of order.airlineNotifications; first as first">
      <div class="alert alert-problem cursor-pointer fs-15 fw-500 my-3"
           *ngIf="!airlineNotification.seen && airlineNotification.actionRequired?.length"
           (click)="onOpenTab()">
        <span class="fs-14">
          <app-date-with-popover [date]="airlineNotification.timestamp * 1000">
          </app-date-with-popover>
        </span> | Airline notification |
        <ng-container *ngFor="let amendment of airlineNotification.amendments; last as last">
          {{amendment.type}} {{!last ? '|' : ''}}
        </ng-container>- <span class="dark-red-color">Action required</span>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #airlineNotificationModal let-c="close">
  <app-airline-notification-modal
    [orderID]="order?.id"
    [airlineID]="order?.bookingReference?.airlineID"
    [airlineNotification]="airlineNotification"
    (emitButtonClick)="onAirlineNotificationModalButtonClick($event)"
    (emitNotificationChange)="onNotificationChange($event)"
    (emitClose)="c('ok')">
    <app-show-processing *ngIf="orderConfirmProcess.isProcess"
      [processInfo]="orderConfirmProcess.processTitle">
    </app-show-processing>
  </app-airline-notification-modal>
</ng-template>
