<ng-template #outdatedBrowserModal let-c="close" let-d="dismiss">
  <div class="modal-header brd-fill">
    <div class="outdated-browser-warning-message">
      Please use Chromium-based browser to access <span>BookingPad</span>.
    </div>
  </div>
  <div class="modal-body">
    <div class="outdated-browser-warning-submessage">
      If you don't have a Chromium-based browser on your desktop, you can download one here:
      <ul class="mb-0">
        <li><a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a></li>
        <li><a href="https://www.microsoft.com/edge" target="_blank">Microsoft Edge</a></li>
        <li><a href="https://www.opera.com/download" target="_blank">Opera Browser</a></li>
        <li><a href="https://brave.com/download/" target="_blank">Brave Browser</a></li>
      </ul>
    </div>
   </div>
  <div class="modal-footer center" *ngIf="isBrowserSupported">
    <button type="button" class="btn btn-outline-dark continue-with-outdated-browser-btn" (click)="continueWithoutChrome(); c('Save click')">Continue without Chromium-based browser</button>
  </div>
</ng-template>

<div class="main-content-wp">
  <app-header [frontendSelectedDependency]="frontendSelectedDependency"></app-header>
  <router-outlet></router-outlet>
  <app-notification></app-notification>
</div>

<div *ngIf="loaderService.isLoad$ | async" class="filter-loader-container">
  <app-filter-loader></app-filter-loader>
</div>

<ng-container *ngIf="versionService.isVersionChanges$ | async">
  <div class="alert-warning p-3 mb-3 mt-3 rounded refresh-alert">
    <div class="refresh-message">BookingPad application has newer version, refresh the page to get it</div>
    <button class="btn btn-primary" (click)="refresh()">Reload</button>
  </div>
</ng-container>


<app-footer *ngIf="frontendSelectedDependency?.showFooter && !frontendSelectedDependency?.showMaintenancePage" [frontendSelectedDependency]="frontendSelectedDependency"></app-footer>


