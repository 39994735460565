<div class="loader-wrapper mb-0" *ngIf="paymentInfoLoader">
  <app-loader></app-loader>
</div>
<div *ngIf="paymentError?.message || paymentInfoError?.message">
  <error-alert-block
    [error]="paymentError?.message ? paymentError : paymentInfoError"
  ></error-alert-block>
</div>

<div class="table-responsive my-4" *ngIf="!paymentInfoLoader">
  <ng-container *ngIf="type === 'pay_offer_on_ordercreate' || type === 'pay_order_on_hold_on_airdocissue'" [ngTemplateOutlet]="orderIssueTable"></ng-container>
  <ng-container *ngIf="type === 'pay_ancillaries_on_orderchange'" [ngTemplateOutlet]="ancillariesTable"></ng-container>
  <ng-container *ngIf="type === 'pay_rebook_on_orderchange'" [ngTemplateOutlet]="orderReIssueTable"></ng-container>
  <ng-container *ngIf="type === 'pay_pending_elements_on_orderchange'" [ngTemplateOutlet]="pendingPaymentsTable"></ng-container>
  <div *ngIf="consumerCurrency !== providerCurrency" class="mt-4">
   {{ paymentService?.form?.get('method')?.value === 'card' ? 'Your card will be be charged for an amount of' : 'You will be billed for an amount of' }}
    {{providerTotal | number :'1.2-2'}} {{providerCurrency}}
  </div>
</div>

<ng-template #orderIssueTable>
  <table class="table table-default highlighted-header-table my-2">
    <tbody>
    <tr>
      <td>Fare</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex cursor-pointer" *ngIf="consumerPrice?.base">
            <app-show-pax-breakdown-info
              [consumerPrice]="consumerPrice"
              [passengers]="passengers"
              [showOnlyFare]="true">
              <span class="price-column text-right">{{consumerPrice.base | number :'1.2-2'}} {{consumerCurrency}}</span>
            </app-show-pax-breakdown-info>
          </div>
          <div class="d-flex gap-5" *ngIf="!consumerPrice?.base">
            <span class="w-20"></span>
            <span class="price-column">-</span>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="consumerTax">
      <td>Taxes</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex cursor-pointer">
            <app-show-taxes-info
              *ngIf="consumerTax?.breakdown && consumerTax?.total; else noTaxInfo"
              [taxesInfo]="consumerTax?.breakdown"
              [currency]="consumerCurrency"
              [showHeader]="false">
              <span class="price-column text-right">{{consumerTax?.total | number :'1.2-2'}} {{consumerCurrency}}</span>
            </app-show-taxes-info>
            <ng-template #noTaxInfo>
              <div class="d-flex gap-5">
                <span class="w-20"></span>
                <span class="price-column" [class.text-right]="consumerTax?.total >= 0">
                  <ng-container *ngIf="consumerTax?.total >= 0">
                    {{consumerTax.total | number :'1.2-2'}} {{consumerCurrency}}
                  </ng-container>
                  <ng-container *ngIf="consumerTax?.total === null || consumerTax?.total === undefined">
                    -
                  </ng-container>
                </span>
              </div>
            </ng-template>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>Fees</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5">
            <span class="w-20"></span>
            <span *ngIf="consumerFee >= 0" class="price-column text-right">{{consumerFee | number :'1.2-2'}} {{consumerCurrency}}</span>
            <span *ngIf="consumerFee === null || consumerFee === undefined" class="price-column">-</span>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="servicesTotal !== null && servicesTotal !== undefined">
      <td>Services</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <app-popover-button class="d-flex gap-5 cursor-pointer"
                              [popoverContent]="servicesPopover">
            <span class="price-column text-right">{{servicesTotal | number :'1.2-2'}} {{consumerCurrency}}</span>
          </app-popover-button>
        </div>
      </td>
    </tr>
    <tr *ngIf="seatsTotal !== null && seatsTotal !== undefined">
      <td>Seats</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <app-popover-button class="d-flex gap-5 cursor-pointer"
                              [popoverContent]="seatsPopover">
            <span class="price-column text-right">{{seatsTotal | number :'1.2-2'}} {{consumerCurrency}}</span>
          </app-popover-button>
        </div>
      </td>
    </tr>
    <tr class="total-price">
      <td>Total</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5 cursor-pointer" *ngIf="consumerTotal">
            <app-show-pax-breakdown-info
              [consumerPrice]="consumerPrice"
              [passengers]="passengers"
              [services]="services"
              [seats]="seats">
              <span #totalElement class="price-column text-right">
                {{consumerTotal | number :'1.2-2'}} {{consumerCurrency}}
              </span>
            </app-show-pax-breakdown-info>
          </div>
          <div class="d-flex gap-5" *ngIf="!consumerTotal">
            <span class="w-20"></span>
            <span class="price-column">-</span>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #orderReIssueTable>
  <table class="table table-default highlighted-header-table my-2">
    <tbody>
    <tr>
      <td>Penalty</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5">
            <span class="w-20"></span>
            <span *ngIf="consumerPenalty !== null && consumerPenalty !== undefined; else showDash"
                  class="price-column text-right">
              {{ consumerPenalty | number :'1.2-2' }} {{ consumerCurrency }}
            </span>
            <ng-template #showDash>
              <span class="price-column">-</span>
            </ng-template>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td style="width: 40%">Fare & Tax Difference</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5">
            <span class="w-20"></span>
            <span *ngIf="reIssueFareAndTaxDifference" class="price-column text-right">{{reIssueFareAndTaxDifference | number :'1.2-2'}} {{consumerCurrency}}</span>
            <span *ngIf="!reIssueFareAndTaxDifference" class="price-column">-</span>
          </div>
        </div>
      </td>
    </tr>
    <tr class="total-price">
      <td>Total</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5 cursor-pointer" *ngIf="consumerPrice">
            <app-show-pax-breakdown-info
              [consumerPrice]="consumerPrice"
              [passengers]="passengers">
              <span #totalElement class="price-column text-right">
                {{consumerTotal | number :'1.2-2'}} {{consumerCurrency}}
              </span>
            </app-show-pax-breakdown-info>
          </div>
          <div class="d-flex gap-5" *ngIf="!consumerPrice">
            <span class="w-20"></span>
            <span class="price-column">-</span>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #ancillariesTable>
  <table class="table table-default highlighted-header-table my-2">
    <tbody>
    <tr class="total-price">
      <td>Total</td>
      <td>
        <app-popover-button class="d-flex align-items-center justify-content-center gap-5 cursor-pointer"
                            [popoverContent]="ancillariesPopover">
          <span>{{consumerTotal | number :'1.2-2'}} {{consumerCurrency}}</span>
        </app-popover-button>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #pendingPaymentsTable>
  <table class="table table-default highlighted-header-table my-2">
    <tbody>
    <tr *ngIf="servicesTotal">
      <td>Services</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5">
            <span class="price-column text-right">{{servicesTotal | number :'1.2-2'}} {{consumerCurrency}}</span>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="seatsTotal">
      <td>Seats</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5">
            <span class="price-column text-right">{{seatsTotal | number :'1.2-2'}} {{consumerCurrency}}</span>
          </div>
        </div>
      </td>
    </tr>
    <tr class="total-price">
      <td style="width: 40%">Total</td>
      <td>
        <div class="d-flex">
          <div class="left">&nbsp;</div>
          <div class="d-flex gap-5">
            <span #totalElement *ngIf="consumerTotal >= 0" class="price-column text-right">
              {{consumerTotal | number :'1.2-2'}} {{consumerCurrency}}
            </span>
            <span *ngIf="consumerTotal === null || consumerTotal === undefined">-</span>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #ancillariesPopover>
  <ng-container [ngTemplateOutlet]="servicesPopover"></ng-container>
  <ng-container [ngTemplateOutlet]="seatsPopover"></ng-container>
</ng-template>

<ng-template #servicesPopover>
  <ng-container *ngIf="services?.length">
    <div class="d-flex justify-content-between gap-30" *ngFor="let service of services">
      <span>{{service.name}}</span>
      <span class="black-color">{{service.price?.consumer?.total | number :'1.2-2'}} {{service.price?.consumer?.currency}}</span>
    </div>
  </ng-container>
</ng-template>

<ng-template #seatsPopover>
  <ng-container *ngIf="seats?.length">
    <div class="d-flex justify-content-between gap-30" *ngFor="let seat of seats">
      <span>{{seat?.location?.row}} {{seat?.location?.column}}</span>
      <span class="black-color">{{seat.price?.consumer?.total | number :'1.2-2'}} {{seat.price?.consumer?.currency}}</span>
    </div>
  </ng-container>
</ng-template>

<div class="table-responsive mt-4" *ngIf="installmentOptions?.length">
  <table class="table table-default highlighted-header-table mb-0">
    <thead>
    <tr>
      <th class="text-center"># Of Installments</th>
      <th>Monthly payment amount</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let option of installmentOptions">
      <td class="text-center">{{option.numberOfInstallments}}</td>
      <td>{{option.installmentAmount.consumer.total | number :'1.2-2'}} {{option.installmentAmount.consumer.currency}}</td>
      <td>
        <label class="d-inline checkbox custom-checkbox-1 mb-0"
               [for]="option.installmentId">
          <input type="checkbox"
                 [id]="option.installmentId"
                 [checked]="paymentService.selectedInstallmentId === option.installmentId"
                 (change)="onInstallmentOptionChange(option.installmentId, $event.target.checked)"/>
          <div class="checkbox__checkmark"></div>
        </label>
      </td>
    </tr>
    </tbody>
  </table>
</div>
