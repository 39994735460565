import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agent} from "../types/agent";
import { ErrorResponse, MetaResponse} from "../types/system";
import { environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HubAgencyService {

  private subUrl = `${environment.ndcApiEndpointV2}agent/access/account/`;

  constructor(public http: HttpClient) { }

  getAll() {
    const url = this.subUrl + 'list';
    return this.http.get<HttpResponse<Agent[]>>(url, this.options());
  }

  addAgent(body): any {
    const url = this.subUrl + 'add';
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  update(body) {
    const url = this.subUrl + 'update';
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  remove(body) {
    const url = this.subUrl + 'remove';
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  sendInvitation(action: string, body: { id: string }) {
    const url = `${this.subUrl}${action}`;
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, body, this.options());
  }

  private options() {
    let options: any = {
      observe: 'response',
    };
    return options;
  }
}
