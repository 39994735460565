<div class="d-flex align-items-center gap-5">
  <i
    *ngIf="!isPDF"
    [ngbPopover]="paxBreakdownInfoTooltip"
    [autoClose]="'outside'"
    popoverClass="pax-breakdown-popover"
    placement="bottom"
    class="material-icons pax-breakdown-info-icon"
    container="body"
    #popover="ngbPopover"
  >
    {{buttonName}}
  </i>

  <ng-content></ng-content>
</div>


<ng-template #paxBreakdownInfoTooltip>
  <div class="pax-breakdown-info-tooltip-wp">
    <ng-container *ngIf="showOnlyFare">
      <ng-container *ngIf="consumerPrice?.breakdown">
        <div *ngFor="let pax of paxes | sortPassengers; last as last">
          <div class="d-flex justify-content-between">
            <span>{{ getTravelerTitle(pax.passengerType) }} </span>
            <span>
              {{ getPaxNumber(pax) }} x
              <span class="text-black"> {{pax.base | number :'1.2-2'}} {{consumerPrice.currency }}</span>
            </span>
          </div>
          <hr *ngIf="!last">
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!showOnlyFare">
      <h4>PAX Breakdown</h4>
      <ng-container *ngIf="consumerPrice?.breakdown">
        <div *ngFor="let pax of paxes | sortPassengers; last as last">
          <div class="d-flex justify-content-between">
            <span>{{ getTravelerTitle(pax.passengerType) }} </span>
            <span>{{ getPaxNumber(pax) }} x <span class="text-black"> {{ getTotal(pax) }} {{consumerPrice.currency}} </span> </span>
          </div>
          <hr>
          <div class="d-flex justify-content-between">
            <span class="ml-3">Fare</span>
            <span class="text-black">{{pax.base | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
          <hr>
          <div class="d-flex justify-content-between">
            <span class="ml-3">Taxes</span>
            <span class="text-black">{{pax?.total | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
          <ng-container *ngIf="pax?.surcharge">
            <hr>
            <div class="d-flex justify-content-between">
              <span class="ml-3">Surcharge</span>
              <span class="text-black">{{pax.surcharge | number :'1.2-2'}} {{consumerPrice.currency }}</span>
            </div>
          </ng-container>
          <hr *ngIf="!last">
        </div>
        <ng-container *ngIf="consumerPrice.fee?.total">
          <hr>
          <div class="d-flex justify-content-between text-nowrap">
            <span>Fees</span>
            <span class="text-black">{{consumerPrice.fee.total | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="services?.length">
          <hr>
          <div class="d-flex justify-content-between text-nowrap">
            <span>Services</span>
            <span class="text-black">{{servicesTotalPrice | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="seats?.length">
          <hr>
          <div class="d-flex justify-content-between text-nowrap">
            <span>Seats selection</span>
            <span class="text-black">{{seatsTotalPrice | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="consumerPrice.discount?.description && consumerPrice.discount?.value">
          <hr>
          <div class="d-flex justify-content-between text-nowrap" style="gap: 30px">
            <span>{{consumerPrice.discount.description}}</span>
            <span class="text-black">- {{consumerPrice.discount.value}} {{consumerPrice.currency }}</span>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!consumerPrice?.breakdown">
        <div class="d-flex justify-content-between">
          <span>{{getTravelerTitle(passengerType) }}</span>
          <span class="text-black"> {{ consumerPrice.total }} {{consumerPrice.currency}} </span>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
          <span class="ml-3">Fare</span>
          <span class="text-black">{{consumerPrice.base | number :'1.2-2'}} {{consumerPrice.currency }}</span>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
          <span class="ml-3">Taxes</span>
          <span class="text-black">{{consumerPrice.tax?.total | number :'1.2-2'}} {{consumerPrice.currency }}</span>
        </div>
        <ng-container *ngIf="consumerPrice.fee?.total">
          <hr>
          <div class="d-flex justify-content-between">
            <span class="ml-3">Fees</span>
            <span class="text-black">{{consumerPrice.fee.total | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="consumerPrice.surcharge">
          <hr>
          <div class="d-flex justify-content-between">
            <span class="ml-3">Surcharge</span>
            <span class="text-black">{{consumerPrice.surcharge | number :'1.2-2'}} {{consumerPrice.currency }}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
