import {NgModule} from "@angular/core";
import {DashboardRoutingModule} from "./dashboard-routing";
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {DashboardComponent} from "./dashboard.component";
import {OfferSearchComponent} from "./offer-search/offer-search.component";
import {SharedModule} from "../shared/shared.module";
import {OfferInfoComponent} from "./offer-info/offer-info.component";
import { AmadeusInputComponent } from './amadeus-input/amadeus-input.component';
import { GdsDetailsComponent } from './gds-details/gds-details.component';
import { OfferBuilderComponent } from './offer-builder/offer-builder.component';
import { PnrSearchComponent } from './pnr-search/pnr-search.component';
import { PnrDropdownComponent } from './pnr-search/pnr-dropdown/pnr-dropdown.component';
import { LegComponent } from './leg/leg.component';
import { OfferHistoryModalComponent } from './offer-history-modal/offer-history-modal.component';
import { SearchSettingsModalComponent } from './search-settings-modal/search-settings-modal.component';

@NgModule({
  imports: [
    DashboardRoutingModule,
    SharedModule,
    NgxSliderModule,
  ],
  declarations: [
    DashboardComponent,
    OfferSearchComponent,
    OfferInfoComponent,
    AmadeusInputComponent,
    GdsDetailsComponent,
    OfferBuilderComponent,
    PnrSearchComponent,
    PnrDropdownComponent,
    LegComponent,
    OfferHistoryModalComponent,
    SearchSettingsModalComponent
  ],
  exports: [
    OfferInfoComponent,
    OfferBuilderComponent
  ],
  providers: []
})
export class DashboardModule {

}
