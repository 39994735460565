<div class="main-offer-builder-wp" *ngIf="allOffers.length">
  <div class="anchor" #desireOfferAnchor></div>

  <div *ngIf="desireOffer"
       class="desired-offer-wp"
       [class.mb-5]="!isReshop && currentOD === desireOffer.flights.length">
    <div *ngIf="searchTypeOption === 'Round-Trip' && currentOD !== desireOffer.flights.length"
         class="change-outbound-flight"
         (click)="removeOD(0)">
      <i class="material-icons" title="change">
        lock
      </i>
      <span class="title">Outbound flight</span> <span class="change">Change</span>
    </div>
    <div class="card"
         [class.mb-0]="isReshop && currentOD === desireOffer.flights.length"
         [ngStyle]="{'border-color': showOptions ? '#4c5d75' : ''}">
      <div class="card-header card-header-custom" *ngIf="currentOD === desireOffer.flights.length">
        <div class="logo">
          <div class="img-item" *ngFor="let sOfferByProvider of simpleOffersByProvider;" >
            <div [ngStyle]="{'cursor': simpleOffersByProvider.length > 1 ? 'pointer' : ''}"
                 [class.selected-provider]="simpleOffersByProvider.length > 1 && desireOffer.owner === sOfferByProvider.owner"
                 [class.unselected-provider]="simpleOffersByProvider.length > 1 && desireOffer.owner !== sOfferByProvider.owner"
                 (click)="setSelectedOffer({offerID: sOfferByProvider.offerID, isOpenNewWindow: false}, true)">
              <app-airline-img
                [class.highlight-img]="simpleOffersByProvider.length > 1 "
                [airlineID]="sOfferByProvider.owner"
                [title]="sOfferByProvider.owner | getImageDescription: sOfferByProvider.flights"
              ></app-airline-img>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <div class="float-right search-offer">
            <div class="text-right" *ngIf="isDiscountExists">
              <span class="old-price">{{oldTotalPrice | number :'1.2-2'}} {{desireOffer.price.consumer.currency}}</span>
              <span class="ml-2 red-color">-{{discountPercentage}}%</span>
            </div>
            <div class="d-flex">
              <div class="mt-1 mr-3">
                <app-fare-type [fareTypeCategories]="filteredGroupNames" [mainFareType]="fareTypeInfo">
                </app-fare-type>
              </div>
              <div *ngIf="currentOD === desireOffer.flights.length">
                <app-show-pax-breakdown-info
                  [consumerPrice]="desireOffer.price.consumer">
                  <div class="bigger-price" [class.new-price-red]="isDiscountExists">
                    <span class="font-weight-bold">
                      {{desireOffer.price.consumer.total | number :'1.2-2'}}
                    </span>
                    <span>
                      {{desireOffer.price.consumer.currency}}
                    </span>
                  </div>
                </app-show-pax-breakdown-info>
                <div class="smaller-price text-right">
                  Taxes: {{desireOffer.price.consumer.tax.total | number :'1.2-2'}} {{desireOffer.price.consumer.currency}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <ng-container *ngFor="let od of desireOffer.flights, index as i; last as last">
          <div class="exist-leg" *ngIf="selectedLegs[i]">
            <div class="segment-wrapper">
              <app-flight-info [flightInfo]="desireOfferFlightInfo[i]" [showRow]="false"></app-flight-info>
            </div>
            <div class="rm-leg-wp" *ngIf="!(searchTypeOption === 'Round-Trip' && currentOD !== desireOffer.flights.length)">
              <i class="material-icons rm-leg" title="back" (click)="removeOD(i)">
                arrow_back
              </i>
            </div>
          </div>
          <div class="no-exist-leg" *ngIf="!selectedLegs[i] && searchTypeOption !== 'Round-Trip'">
            <div class="route">
              <span [appTooltip]="{airportCode: originDestinations[i]?.departure?.airportCode}">
                {{originDestinations[i]?.departure?.airportCode}}
              </span>
              <span>→</span>
              <span [appTooltip]="{airportCode:originDestinations[i]?.arrival?.airportCode}">
                {{originDestinations[i]?.arrival?.airportCode}}
              </span>
            </div>
            <div class="question-char">?</div>
          </div>
          <hr class="bottom-line" *ngIf="searchTypeOption !== 'Round-Trip' ? (i !== desireOffer.flights.length - 1) : (currentOD === desireOffer.flights.length && i !== desireOffer.flights.length - 1)">
        </ng-container>
      </div>

      <div class="card-footer"
           *ngIf="currentOD === desireOffer.flights.length"
           [ngStyle]="{'padding-bottom': showOptions && !showLoader ? 0 : ''}">
        <div class="row align-items-center">
          <div class="col-md-4"></div>
          <div class="col ml-4 font-weight-bold">
            <app-offer-expiration [time]="desireOffer.createdAt" [expiresAt]="desireOffer.offerExpiration" (emitOfferExpiration)="onOfferExpired($event)"></app-offer-expiration>
          </div>
          <div class="col-md-4 d-flex justify-content-end">
            <div *ngIf=!hasRepricingStarted class="buttons-wp"
                 [ngbPopover]="missingFlights"
                 popoverClass="missing-flights-popover-wp"
                 popoverTitle="Missing flights:"
                 [triggers]="'hover'"
                 [disablePopover]="currentOD === desireOffer.flights.length"
                 placement="top">
              <button class="btn btn-primary"
                      (click)="toggleOptions()">
                {{showOptions ? '-' : '+'}} Fares
                <span *ngIf="simpleOffersModel.length > 1">({{simpleOffersModel.length}})</span>
              </button>
            </div>
          </div>
        </div>

        <app-options
          [showOptions]="showOptions"
          [simpleOffers]="groupedOffersByProvider | filterSimpleOffer"
          [desireOffer]="desireOffer"
          [isReshop]="isReshop"
          [showLoader]="showLoader"
          (onSelectedOffer)="setSelectedOffer($event)"
          [isOfferExpired]="offerExpired"
        >
        </app-options>
      </div>
    </div>
  </div>

  <ng-container *ngIf="(desireOffer && searchTypeOption === 'Round-Trip')">
    <div class="offer-list-data-wp mb-3" *ngIf="currentOD !== desireOffer.flights.length">
      <h4 class="d-flex align-items-center gap-4 mb-0">
        Inbound itineraries:
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34383 2.27187C5.70633 1.90938 6.29383 1.90938 6.65633 2.27187L10.7282 6.34438C11.0907 6.70688 11.0907 7.29375 10.7282 7.65562L6.65633 11.7281C6.29383 12.0906 5.70696 12.0906 5.34509 11.7281L1.27196 7.65625C1.18575 7.57022 1.11735 7.46802 1.07069 7.35552C1.02402 7.24302 1 7.12242 1 7.00063C1 6.87883 1.02402 6.75823 1.07069 6.64573C1.11735 6.53323 1.18575 6.43104 1.27196 6.345L5.34383 2.27187Z" fill="#9747ff"/>
        </svg>
        {{availableOffers.length}}
        <span>/</span>
        Inbound Offers:
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
          <path d="M5 10.5C4.30833 10.5 3.65833 10.3687 3.05 10.106C2.44167 9.84333 1.9125 9.48717 1.4625 9.0375C1.0125 8.5875 0.656333 8.05833 0.394 7.45C0.131667 6.84167 0.000333333 6.19167 0 5.5C0 4.80833 0.131333 4.15833 0.394 3.55C0.656667 2.94167 1.01283 2.4125 1.4625 1.9625C1.9125 1.5125 2.44167 1.15633 3.05 0.894C3.65833 0.631667 4.30833 0.500333 5 0.5C5.69167 0.5 6.34167 0.631333 6.95 0.894C7.55833 1.15667 8.0875 1.51283 8.5375 1.9625C8.9875 2.4125 9.34383 2.94167 9.6065 3.55C9.86917 4.15833 10.0003 4.80833 10 5.5C10 6.19167 9.86867 6.84167 9.606 7.45C9.34333 8.05833 8.98717 8.5875 8.5375 9.0375C8.0875 9.4875 7.55833 9.84383 6.95 10.1065C6.34167 10.3692 5.69167 10.5003 5 10.5Z" fill="#4aaea8"/>
        </svg>
        {{(allOffers | groupByODAndMarketingName: selectedOffer : currentOD).length}}
      </h4>

      <div class="leg-headers" *ngIf="currentOD !== desireOffer.flights.length">
        <h3 *ngIf="currentOD > 0" class="other-leg-headers">
          Select
          <span [appTooltip]="{airportCode: originDestinations[currentOD]?.departure?.airportCode}">
            {{originDestinations[currentOD]?.departure?.airportCode}}
          </span>
          <span>
            →
          </span>
          <span [appTooltip]="{airportCode: originDestinations[currentOD]?.arrival?.airportCode}">
            {{originDestinations[currentOD]?.arrival?.airportCode}}
          </span>
          flight
        </h3>
      </div>

      <div class="sort-select-wp">
        <div class="mr-2">Sort By: </div>
        <app-custom-select
          class="sort-strategy-select"
          [(ngModel)]="sortStrategy"
          [options]="sortStrategyOptions | keys"
          [mapObject]="sortStrategyOptions"
          (ngModelChange)="changeSortStrategy($event)">
        </app-custom-select>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="(searchTypeOption !== 'Round-Trip') || (!desireOffer && searchTypeOption === 'Round-Trip')">
    <div class="offer-list-data-wp mb-3" *ngIf="!desireOffer || currentOD !== desireOffer.flights.length">
      <h4 class="d-flex align-items-center gap-4 mb-0" *ngIf="!isReshop">
        Itineraries:
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34383 2.27187C5.70633 1.90938 6.29383 1.90938 6.65633 2.27187L10.7282 6.34438C11.0907 6.70688 11.0907 7.29375 10.7282 7.65562L6.65633 11.7281C6.29383 12.0906 5.70696 12.0906 5.34509 11.7281L1.27196 7.65625C1.18575 7.57022 1.11735 7.46802 1.07069 7.35552C1.02402 7.24302 1 7.12242 1 7.00063C1 6.87883 1.02402 6.75823 1.07069 6.64573C1.11735 6.53323 1.18575 6.43104 1.27196 6.345L5.34383 2.27187Z" fill="#9747ff"/>
        </svg>
        {{availableOffers.length}}
        <span>/</span>
        Offers:
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
          <path d="M5 10.5C4.30833 10.5 3.65833 10.3687 3.05 10.106C2.44167 9.84333 1.9125 9.48717 1.4625 9.0375C1.0125 8.5875 0.656333 8.05833 0.394 7.45C0.131667 6.84167 0.000333333 6.19167 0 5.5C0 4.80833 0.131333 4.15833 0.394 3.55C0.656667 2.94167 1.01283 2.4125 1.4625 1.9625C1.9125 1.5125 2.44167 1.15633 3.05 0.894C3.65833 0.631667 4.30833 0.500333 5 0.5C5.69167 0.5 6.34167 0.631333 6.95 0.894C7.55833 1.15667 8.0875 1.51283 8.5375 1.9625C8.9875 2.4125 9.34383 2.94167 9.6065 3.55C9.86917 4.15833 10.0003 4.80833 10 5.5C10 6.19167 9.86867 6.84167 9.606 7.45C9.34333 8.05833 8.98717 8.5875 8.5375 9.0375C8.0875 9.4875 7.55833 9.84383 6.95 10.1065C6.34167 10.3692 5.69167 10.5003 5 10.5Z" fill="#4aaea8"/>
        </svg>
        {{currentOD > 0 ? availableOffers.length : allOffers.length}}
      </h4>

      <div class="leg-headers">
        <h3 *ngIf="currentOD === 0" class="first-leg-header">
          Select outbound flight
          <span [appTooltip]="{airportCode: originDestinations[0]?.departure?.airportCode}">
            ({{originDestinations[0].departure.airportCode}}
          </span>
          <span>
            →
          </span>
          <span [appTooltip]="{airportCode: originDestinations[0]?.arrival?.airportCode}">
            {{originDestinations[0]?.arrival?.airportCode}})
          </span>
        </h3>
        <h3 *ngIf="currentOD > 0" class="other-leg-headers">
          Select
          <span [appTooltip]="{airportCode: originDestinations[currentOD]?.departure?.airportCode}">
            {{originDestinations[currentOD]?.departure?.airportCode}}
          </span>
          <span>
            →
          </span>
          <span [appTooltip]="{airportCode: originDestinations[currentOD]?.arrival?.airportCode}">
            {{originDestinations[currentOD]?.arrival?.airportCode}}
          </span>
          flight
        </h3>
      </div>

      <div class="sort-select-wp">
        <div class="mr-2">Sort By: </div>
        <app-custom-select
          class="sort-strategy-select"
          [(ngModel)]="sortStrategy"
          [options]="sortStrategyOptions | keys"
          [mapObject]="sortStrategyOptions"
          (ngModelChange)="changeSortStrategy($event)">
        </app-custom-select>
      </div>
    </div>
  </ng-container>

  <div class="viewport-wp"
       *ngIf="!desireOffer || currentOD !== desireOffer.flights.length">
    <div class="viewport">
      <ng-container *ngFor="let offer of showAvailableOffers;trackBy: trackByFn; index as offerIdx">
        <app-leg
          *ngIf="offer && currentOD === 0 || (currentOD > 0 && ((allOffers | filterOfferByCabinClass: offer : currentOD: selectedOffer)).length > 0)"
          [offer]='offer'
          [selectedOffer]='selectedOffer'
          (emitSelectOD)='onSelectOD($event, desireOfferAnchor)'
          [currentOD]='currentOD'
          [appropriateOffers]="allOffers | filterOfferByCabinClass: offer : currentOD: selectedOffer"
          [showRow]="isReshop"
        >
        </app-leg>
      </ng-container>
    </div>
  </div>
</div>

<div class="button-to-top-wp" *ngIf="pageSize > 6 && !isReshop">
  <button (click)="windowToTop($event)"><i class="material-icons">keyboard_arrow_up</i></button>
</div>

<ng-template #missingFlights>
  <ul>
    <li *ngFor="let od of desireOffer.flights | slice:currentOD">
      {{od.departure.airportCode}} → {{od.arrival.airportCode}}
    </li>
  </ul>
</ng-template>
