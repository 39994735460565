<div>
  <div *ngIf="operatingFlightNumber"
       class="d-flex justify-content-between gap-20"
       [class.mb-2]="aircraftCode">
    <span>Operating flight number</span>
    <span class="black-color">{{operatingFlightNumber}}</span>
  </div>
  <div *ngIf="aircraftCode"
       class="d-flex justify-content-between gap-20">
    <span>Aircraft</span>
    <span class="black-color">{{aircraftCode}}</span>
  </div>
</div>
